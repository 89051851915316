import React, { Component } from "react";
import "../../index.css";
import { EditorInfoType, FONT_SIZE, LINE_ITEM_HEIGHT, PAGE_PADDING } from '../../Constants/Constants';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from "../../Manager/DocumentManager";
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from "../../Utilities/HTMLTag";
import Utility, { getFontSizeClass, getIsDocBuilderApp, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry, getPageHeight, getVW } from "../../Utilities/Utility";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import "./css/Templates.css";
import DKComponentListTable from "../../DKUILibrary/DKComponentListTable";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";

export default class DocumentTemplate23 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30)  }}>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    {this.getHeaderSection()}
                    {this.getTableSection()}
                    {this.getShipFromShipToSection()}
                </div>
                <div className='parent-width' style={{ width: '100%' }}>
                    {this.state.data.showFrom && this.getFooterSection()}
                </div>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
            </div>
        );
    }

    /////// Header Section ///////////
    getHeaderSection() {
        return (
            <div style={{
                width: '100%', borderRightColor: '#000000',
                borderRightWidth: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)), borderRightStyle: 'solid', borderTopColor: '#000000',
                borderTopWidth: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)), borderTopStyle: 'solid', borderLeftColor: '#000000',
                borderLeftWidth: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)), borderLeftStyle: 'solid'
            }}>
                <div className="RowDiv">
                    <div className="ColumnDiv" style={{marginLeft: '5%', marginTop: '4%', width: '60%'}}>
                        <div>
                            {this.state.data.showCompanyName && this.getCompanyName()}
                        </div>
                        <div>
                            {ComponentManager.addVerticalSpace(10)}
                            <text className={"docFontStyleBold" + getFontSizeClass()}
                                style={{
                                    color: "#000000",
                                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xLarge }, this.state.data.printScale)
                                }}>
                                { getLocalisedText(this.state.data.documentType) }</text>
                            {ComponentManager.addVerticalSpace(10)}
                        </div>
                        <div style={{width: '50%'}}>
                            {this.getDocumentDetailsSection()}
                            {ComponentManager.addVerticalSpace(20)}
                        </div>
                    </div>
                    <div style={{width: '40%'}}>
                        {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(75, this.state.data.printScale), {position: 'relative'})}
                    </div>
                </div>
                <div className='RowDiv' style={{paddingRight: '5%', paddingLeft: '5%', width: '90%'}}>
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'top'} />
                </div>
                {ComponentManager.addVerticalSpace(20)}
            </div>
        )
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map(element => {
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }
            return ComponentManager.getRowValueSection(
                element.label,
                value,
                "#4E4E4E",
                "#4E4E4E",
                undefined,
                undefined,
                this.state.data.printScale
            )
        });
    }

    getCompanyName() {
        return <>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    marginTop: getVW(15),
                    color: '#000000',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.large }, this.state.data.printScale)
                }}>
                    {getLocalisedText(Utility.getCompanyName(this.state.data))}
            </text>
            {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
            {ComponentManager.addDivider({backgroundColor: '#000000', height: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)), width: '75%'})}
        </>;
    }

/////// Table Section ///////////
    getTableSection() {
        return (
            <div style={{
                backgroundColor: this.state.data.themeColor,
                paddingLeft: `${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`,
                paddingRight: `${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`,
                paddingTop: getVW(Utility.convertToPrintScale(15, this.state.data.printScale)),
                marginLeft: getIsDocBuilderApp() ? `-${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw` : 0,
                width: '100%'
                }}>
                {this.getProductTable()}
                {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(Utility.convertToPrintScale(10, this.state.data.printScale))}
                {
                    TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                    <>
                        <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                            dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                        {ComponentManager.addVerticalSpace(5)}
                    </>
                }
                <div style={{ position: 'relative', top: getVW(`${Utility.convertToPrintScale(getIsDocBuilderApp() ? Number(LINE_ITEM_HEIGHT.replace('vw', '')) : 3.25, this.state.data.printScale)}vw`, true), width:"100%", height: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)), backgroundColor: 'black', zIndex: 2}}/>
                <div>
                    {this.getEditableDocumentTable()}
                    <div style={{position:"relative", bottom: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)), width:"100%", height: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)), backgroundColor: 'black'}}/>
                    <div style={{ position: "relative", bottom: getVW(Utility.convertToPrintScale(getIsDocBuilderApp() ? 160 : 170, this.state.data.printScale)), width: "100%", height: this.getHeightForFooterLine(), backgroundColor: 'black'}}/>
                    {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                </div>
            </div>
        )
    }

    getHeightForFooterLine() {
        if(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) {
            return undefined
        }

        return getVW(Utility.convertToPrintScale(2, this.state.data.printScale))
    }

    /////// Ship From & Ship To Section ///////////
    getShipFromShipToSection() {
        return (

            <div style={{
                width: '100%', borderRightColor: '#000000',
                borderRightWidth: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)), borderRightStyle: 'solid', borderBottomColor: '#000000',
                borderBottomWidth: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)), borderBottomStyle: 'solid', borderLeftColor: '#000000',
                borderLeftWidth: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)), borderLeftStyle: 'solid'
            }}>
                {Utility.getIsShowExchangeRate(this.state.data) && this.getExchangeRateTable(this.getTableStyle(), this.state.data)}
                {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(20)}
                {this.getTransactionDocumentTable(this.getTableStyle(), this.state.data)}
                <div className='RowDiv' style={{ paddingRight: '5%', paddingLeft: '5%', width: '90%' }}>
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'bottom'} />
                </div>
                <div className='RowDiv' style={{ paddingRight: '5%', paddingLeft: '5%', width: '90%' }}>
                    {Utility.getIsIncludesBomProductTable() && <DKComponentListTable data={this.state.data} />}
                </div>
                <div className="ColumnDiv">
                    <div className="RowDiv break-inside-avoid" id={HTMLTag.SIGNATURE}>
                        {ComponentManager.addVerticalSpace(5)}
                        {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                    </div>
                    <div className="RowDiv"
                        style={{marginLeft: '5%', marginTop: '3%', width: '90%', alignItems: 'flex-start'}}>
                        <div style={{width: '50%'}}>
                            <div className="ColumnDiv" style={{width: '100%'}}>
                                {this.state.data.showClientBillToAddress && this.getBillingAddress()}
                                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.addVerticalSpace(10)}
                                {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                            </div>
                        </div>
                        <div style={{width: '50%'}}>
                            <div className="ColumnDiv" style={{width: '100%', alignItems: 'flex-end'}}>
                                {this.state.data.showClientShipToAddress && this.getShippingAddress()}
                            </div>
                        </div>
                    </div>
                    <div className="RowDiv"
                        style={{marginLeft: '5%', marginTop: '3%', width: '90%', alignItems: 'flex-start'}}>
                        <div style={{width: '50%'}}>
                            <div className="ColumnDiv" style={{width: '100%'}}>
                                {Utility.getIsShowSourceOfSupply(this.state.data) && this.getSourceOfSupply()}
                            </div>
                        </div>
                        <div style={{width: '50%'}}>
                            <div className="ColumnDiv" style={{width: '100%', alignItems: 'flex-end'}}>
                                {Utility.getIsShowDestinationOfSupply(this.state.data) && this.getDestinationOfSupply()}
                            </div>
                        </div>
                    </div>
                    {ComponentManager.addVerticalSpace(30)}
                    <div className='RowDiv' style={{ paddingRight: '5%', paddingLeft: '5%', width: '90%' }}>
                        {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />}
                    </div>
                    <div style={{marginLeft: '5%', width: '90%'}}>
                        <div id={HTMLTag.TERMS_NOTE_PREFIX} className="ColumnDiv">
                            {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && ComponentManager.getTermsAndConditionOrNotesSection(
                                Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                                this.state.data.termsAndCondition,
                                "100%",
                                undefined,
                                undefined,
                                undefined,
                                getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                                this.state.data.printScale,
                                Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                            )}
                            {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && (this.state.data.showNotes && this.state.data.notes)) && ComponentManager.addVerticalSpace(10)}
                            {(this.state.data.showNotes && this.state.data.notes) && ComponentManager.getTermsAndConditionOrNotesSection(
                                Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                                this.state.data.notes,
                                "100%",
                                undefined,
                                undefined,
                                undefined,
                                getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                                this.state.data.printScale,
                                Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes)
                            )}
                        </div>
                    </div>
                </div>
                {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} />}
                {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(40, this.state.data.printScale))}
            </div>
        )
    }

    getBillingAddress() {
        return <>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    textAlign: 'left',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo) }, this.state.data.printScale)
                }}>{getLocalisedText(this.state.data.billToTitle).toUpperCase() + ':'}</text>
            {ComponentManager.addVerticalSpace(5)}
            <div className={"docFontStyle" + getFontSizeClass()}
                style={{
                    overflow: 'hidden',
                    textAlign: 'left',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo) }, this.state.data.printScale)
                }}
                    dangerouslySetInnerHTML={{__html: getLocalisedText(Utility.getClientBillToAddress(this.state.data))}}>
            </div>
        </>;
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }
    getPlaceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '100%'
            }}>
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('place_of_supply')+":",
                    this.state.data.placeOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true
                )}
            </div>

        </div>;

    }

    getShippingAddress() {
        return <>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    textAlign: 'right',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.shipTo) }, this.state.data.printScale)
                }}>{getLocalisedText(this.state.data.shipToTitle).toUpperCase() + ':'}</text>
            {ComponentManager.addVerticalSpace(5)}
            <div className={"docFontStyle" + getFontSizeClass()}
                style={{
                    overflow: 'hidden',
                    textAlign: 'right',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.shipTo) }, this.state.data.printScale)
                }}
                    dangerouslySetInnerHTML={{__html: getLocalisedText(Utility.getClientShipToAddress(this.state.data))}}>
            </div>
        </>;
    }

    getSourceOfSupply() {
        return <>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    textAlign: 'left',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale)
                }}>{getLocalisedText('source_of_supply')+ ':'}</text>
            {ComponentManager.addVerticalSpace(5)}
            <div className={"docFontStyle" + getFontSizeClass()}
                style={{
                    overflow: 'hidden',
                    textAlign: 'left',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale)
                }}
                    dangerouslySetInnerHTML={{__html:this.state.data.sourceOfSupply}}>
            </div>
        </>;
    }

    getDestinationOfSupply(){
        return <>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    textAlign: 'left',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale)
                }}>{getLocalisedText('destination_of_supply')+ ':'}</text>
            {ComponentManager.addVerticalSpace(5)}
            <div className={"docFontStyle" + getFontSizeClass()}
                style={{
                    overflow: 'hidden',
                    textAlign: 'left',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale)
                }}
                    dangerouslySetInnerHTML={{__html:this.state.data.destinationOfSupply}}>
            </div>
        </>;
    }

/////// Footer Section ///////////
    getFooterSection() {
        return (
            <div className="RowDiv" style={{justifyContent: 'center'}}>
                <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        textAlign: 'right',
                        overflow: 'hidden',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale)
                    }}
                        dangerouslySetInnerHTML={{__html:getLocalisedText(Utility.getCompanyName(this.state.data)) +  ' - ' + getLocalisedText(Utility.getCompanyAddress(this.state.data))}}>
                </div>

            </div>
        )
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = "#000000";
        style.footerTextColor = "#000000";
        style.headerBackgroundColor = this.state.data.themeColor;
        style.headerBorderColor = this.state.data.themeColor;
        style.itemBorderColor = this.state.data.themeColor;
        style.footerBackgroundColor = this.state.data.themeColor;
        style.footerBorderColor = this.state.data.themeColor;
        return style;
    }

    getExchangeRateTable(tableStyle, data) {
        const style = {
            ...tableStyle,
            headerBorderColor: "transparent",
            headerBorderBottomColor: "black",
            headerBorderBottomWidth: getVW(1)
        };

        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{
                justifyContent: "flex-end",
                paddingLeft: `${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), data.printScale)}vw`,
            }}>
                <div style={{
                    backgroundColor: this.state.data.themeColor,
                    paddingRight: `${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), data.printScale)}vw`,
                    width: '45%'
                }}>
                    <div style={{ height: getVW(1), backgroundColor: 'black' }} />
                    {ComponentManager.getExchangeRateTable(style, data)}
                </div>
            </div>
        );
    }

    getTransactionDocumentTable(tableStyle, data) {
        const style = {
            ...tableStyle,
            headerBorderColor: "transparent",
            headerBorderBottomColor: "black",
            headerBorderBottomWidth: getVW(1)
        };

        return (
            <div id={HTMLTag.TRANSACTION_DOCUMENT_TABLE} className="RowDiv" style={{
                justifyContent: "flex-end",
                paddingLeft: `${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), data.printScale)}vw`,
            }}>
                <div style={{
                    backgroundColor: this.state.data.themeColor,
                    paddingRight: `${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), data.printScale)}vw`,
                    width: '65%'
                }}>
                    <div style={{ height: getVW(1), backgroundColor: 'black' }} />
                    {ComponentManager.getTransactionDocumentTable(style, data)}
                </div>
            </div>
        );
    }
}
