import { CONSTANTS, DateFormat, TableColumnPopupType } from "../../../Constants/Constants";
import IndiaTaxParser from "../../../Helper/IndiaTaxParser";
import AppManager from "../../../Manager/AppManager";
import ComponentManager from "../../../Manager/ComponentManager";
import CustomFieldManager from "../../../Manager/CustomFieldManager";
import TemplateSettingsManager from "../../../Manager/TemplateSettingsManager";
import { getLocalisedText } from "../../../Translate/LanguageManager"
import NumberUtility from "../../../Utilities/NumberUtility";
import TableFooterUtility from "../../../Utilities/TableFooterUtility";
import Utility, { convertDateFromERPFormatToDocFormat, getConvertedDate, getDateFrom, getDateString, getIsInvoice, getIsSalesOrder } from "../../../Utilities/Utility";
import { getSortedHeader } from "../TableUtility";
export class ProductTableItem {
    lineNumber: number;
    code: string;
    image: string;
    name: string;
    quantity: string;
    description: string;
    hsn: string;
    productCustomField: string;
    unitPrice: string;
    tax: number;
    discount: number;
    uom: string;
    quantityWithUom: string;
    serialBatch: string;
    serialNumber: string;
    batchNumber: string;
    manufacturingDate: string;
    expiryDate: string;
    amountBeforeTax: any;
    taxName: string;
    taxAmount: string;
    priceInclusiveOfTax: boolean;
    currency: string;
    preTaxAmount: number | undefined;
    globalDiscount: any;
    unitPriceAfterDiscount: number;
    discountPerUnit: number;
    expectedDeliveryDate: string;
    qtyConvertedToWo: string;
    rawItemValue: any;
    data: any;
    availableQuantity: any;

    constructor(lineItem: any, data: any, currency: string) {
        this.lineNumber = lineItem.lineNumber
        this.code = lineItem.code
        this.image = lineItem.image
        this.name = this.getProductName(data, lineItem)
        this.hsn = lineItem.hsnOrSacCode
        this.quantity = lineItem.quantity
        this.productCustomField = ''
        this.uom = this.getProductUOM(lineItem)
        this.quantityWithUom = lineItem.quantity + ' x ' + this.getProductUOM(lineItem)
        this.serialBatch = lineItem.serialBatch ? lineItem.serialBatch : ''
        this.serialNumber = lineItem.serialNumber ? lineItem.serialNumber : ''
        this.batchNumber = lineItem.batchNumber ? lineItem.batchNumber : ''
        this.manufacturingDate = lineItem.manufacturingDate ? lineItem.manufacturingDate : ''
        this.availableQuantity = lineItem.availableQuantity ? lineItem.availableQuantity : ''
        this.expiryDate = lineItem.expiryDate ? lineItem.expiryDate : ''
        this.description = this.getProductDescription(lineItem)
        // this.nameWithDescription = '<b>' + this.getProductName(data, lineItem) + '</b> \n' + this.getProductDescription(lineItem)
        this.unitPrice = lineItem.unitPrice
        this.tax = lineItem.tax
        this.taxName = lineItem.tax
        this.taxAmount = ''
        this.discount = lineItem.discount
        this.rawItemValue = lineItem
        this.priceInclusiveOfTax = data.priceInclusiveOfTax
        this.amountBeforeTax = lineItem.amountBeforeTax
        this.preTaxAmount = lineItem.preTaxAmount
        this.unitPriceAfterDiscount = (lineItem.unitPriceAfterDiscount !== undefined && lineItem.unitPriceAfterDiscount !== null) ? lineItem.unitPriceAfterDiscount : 0
        this.discountPerUnit = (lineItem.discountPerUnit !== undefined && lineItem.discountPerUnit !== null) ? lineItem.discountPerUnit : 0
        this.currency = currency
        this.data = data
        this.expectedDeliveryDate = this.getExpectedDeliveryDate(lineItem, data)
        this.qtyConvertedToWo = lineItem.qtyConvertedToWo ?? 0
        this.globalDiscount = this.getGlobalDiscount(lineItem)
    }

    getExpectedDeliveryDate(lineItem: any, data: any) {
        var date = ''
        if (lineItem !== undefined && lineItem !== null) {
            if (lineItem.expectedDeliveryDt !== undefined && lineItem.expectedDeliveryDt !== null) {
                date = lineItem.expectedDeliveryDt

                if (date !== '') {
                    if (data.dateFormat !== undefined && data.dateFormat !== null) {
                        let formattedDate = getConvertedDate(convertDateFromERPFormatToDocFormat(date), data.dateFormat)

                        if (!formattedDate.includes('NaN')) {
                            date = formattedDate
                        }
                    }
                }
            }
        }
        return date
    }

    getGlobalDiscount(lineItem: any) {
        let list: any[] = []

        if (lineItem.additionalCharges !== undefined && lineItem.additionalCharges !== null) {
            if (lineItem.additionalCharges.globalDiscount !== undefined && lineItem.additionalCharges.globalDiscount !== undefined) {
                list.push(lineItem.additionalCharges.globalDiscount)
            }
            if (lineItem.additionalCharges.globalDiscounts !== undefined && lineItem.additionalCharges.globalDiscounts !== undefined) {
                list = lineItem.additionalCharges.globalDiscounts
            }
        }

        return list
    }

    getProductName(data: any, lineItem: any): string {
        var name = ''
        if (data.country === 'SA') {
            if (lineItem.nameInArabic !== undefined && lineItem.nameInArabic !== null) {
                if (lineItem.nameInArabic !== '') {
                    name = getLocalisedText(lineItem.name) + '\n' + getLocalisedText(lineItem.nameInArabic)
                }
            }
        }
        if (lineItem.name !== undefined && lineItem.name !== null) {
            name = getLocalisedText(lineItem.name)
        }

        // let bomProduct = this.getBOMProductDetails(lineItem);
        // if(name !== '' && bomProduct !== '') {
        //     name = name + '\n' + this.getBOMProductDetails(lineItem);
        // }

        name = name + this.getProductOptionalTag(lineItem)
        return name
    }

    getProductOptionalTag(lineItem: any) {
        let optional = lineItem.optional ?? false

        var newProductGrouping = TemplateSettingsManager.getProductGrouping()
        var grouping = 'none'
        if (newProductGrouping !== undefined && newProductGrouping !== null) {
            if (newProductGrouping.selection !== undefined) {
                grouping = newProductGrouping.selection
            }
        }

        if (grouping === CONSTANTS.PRODUCT_GROUP) {
            let isMultiProductTable = TemplateSettingsManager.getIsMultiProductTable()

            if (isMultiProductTable) {
                if (TemplateSettingsManager.getIsShowOptionalProductGroupTable()) {
                    optional = false
                }
            }
        }

        if(optional) {
            return '\n(' + getLocalisedText('optional') + ')'
        }

        return ''
    }

    getBOMProductDetails(lineItem: any): string {
        var bomProductString = ''

        let isIncludesBomProduct = Utility.getProductTableConfiguration('isIncludesBomProductTable') ?? false

        if(isIncludesBomProduct) {
            if (lineItem.bomComponentGroups && lineItem.bomComponentGroups !== null) {
                if (lineItem.bomComponentGroups.length > 0) {
                    lineItem.bomComponentGroups.forEach((element: any, index: number) => {
                        const startIndex = index + 1
                        if (element.productGroupName && element.productGroupName !== null) {
                            bomProductString = bomProductString + startIndex.toFixed(0) + ' ' + element.productGroupName + '\n'
                        }

                        if (element.bomComponentGroupItems && element.bomComponentGroupItems !== null) {
                            if (element.bomComponentGroupItems.length > 0) {
                                element.bomComponentGroupItems.forEach((bomComponent: any, index: number) => {
                                    if (bomComponent.productName && bomComponent.productName !== null) {
                                        const bomComponentIndex = index + 1
                                        bomProductString = bomProductString + startIndex.toFixed(0) + '.' + bomComponentIndex.toFixed(0) +  '  ' + bomComponent.productName
                                    }
                                    if (bomComponent.productCode && bomComponent.productCode !== null) {
                                        if (lineItem.bomProductsConfiguration && lineItem.bomProductsConfiguration) {
                                            const bomProduct = lineItem.bomProductsConfiguration.find((x: any) => x.productCode === bomComponent.productCode) 

                                            if(bomProduct) {
                                                const type = this.getProductType(bomProduct)
                                                if(type !== '') {
                                                    bomProductString = bomProductString + ' (' + type + ') '
                                                }
                                            }
                                        }
                                    }
                                    bomProductString = bomProductString + '\n'
                                });
                            }
                        }
                        
                        
                    });
                }
            }
        }

        return bomProductString
    }

    getProductType(bomProduct: any): string {
        let type = ''
        if (bomProduct.produceProductType && bomProduct.produceProductType !== null) {
            if (bomProduct.produceProductType === 'SCRAP') {
                type = 'Type: SCRAP'
            }
            else if (bomProduct.produceProductType === 'COPRODUCT' || bomProduct.produceProductType === 'CO-PRODUCT') {
                type = 'Type: CO-PRODUCT'
            }
        }

        return type
    }

    getProductDescription(lineItem: any): string {
        if (lineItem.description !== undefined && lineItem.description !== null) {
            return getLocalisedText(lineItem.description)
        }
        return ''
    }


    getProductUOM(lineItem: any): string {
        var uom = lineItem.uom ? lineItem.uom : ''

        if (TemplateSettingsManager.getIsAlterUOMSelected(undefined)) {
            if (lineItem.alterUOM !== undefined && lineItem.alterUOM !== null) {
                return uom + ' ' + lineItem.alterUOM
            }
        }
        return uom
    }
    getProductHSN(lineItem: any): string {

        if (TemplateSettingsManager.getIsHSNSelected(undefined)) {
            if (lineItem.hsnOrSacCodeString !== undefined && lineItem.hsnOrSacCodeString !== null) {
                return lineItem.hsnOrSacCodeString
            }
        }
        return ''
    }
    getCombineProductCustomField() {
        var val = ''
        var productTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.productTableConfiguration
        if (productTableConfiguration !== undefined) {
            if (productTableConfiguration.isCombinesProductCustomField) {
                var productCustomFields = CustomFieldManager.getActiveProductCustomFields()
                if (productCustomFields !== undefined) {
                    if (productCustomFields.length > 0) {
                        productCustomFields.forEach(element => {
                            if (element.isSelected && element.code !== undefined) {
                                var field = this.getProductCustomFieldByCode(element.code)
                                if (field !== undefined) {
                                    var cfType = element.cfType;
                                    if (cfType === undefined) {
                                        var customField = CustomFieldManager.getProductCustomFields().find((cf) => cf.code === element.code);
                                        if (customField && customField.fieldType) {
                                            cfType = customField.fieldType;
                                        }
                                    }

                                    if (cfType === "DATE") {
                                        val += element.label + ': <b>' + (getDateString(getDateFrom(field.value, DateFormat.MM_DD_YYYY_SLASH), CustomFieldManager.getDateFormat()) || field.value) + '</b>\n'
                                    } else {
                                        val += element.label + ': <b>' + field.value + '</b>\n'
                                    }
                                }
                            }
                        });
                    }
                    else {
                        if (productTableConfiguration.combinedProductCustomField !== undefined
                            && productTableConfiguration.combinedProductCustomField !== null) {
                            if (productTableConfiguration.combinedProductCustomField.length > 0) {
                                productTableConfiguration.combinedProductCustomField.forEach((element: any) => {
                                    if (element.isSelected && element.code !== undefined) {
                                        var field = this.getProductCustomFieldByCode(element.code)
                                        if (field !== undefined) {
                                            var cfType = element.cfType;
                                            if (cfType === undefined) {
                                                var customField = CustomFieldManager.getProductCustomFields().find((cf) => cf.code === element.code);
                                                if (customField && customField.fieldType) {
                                                    cfType = customField.fieldType;
                                                }
                                            }

                                            if (cfType === "DATE") {
                                                val += element.label + ': <b>' + (getDateString(getDateFrom(field.value, DateFormat.MM_DD_YYYY_SLASH), CustomFieldManager.getDateFormat()) || field.value) + '</b>\n'
                                            } else {
                                                val += element.label + ': <b>' + field.value + '</b>\n'
                                            }
                                        }
                                    }
                                });
                            }
                        }
                    }
                }
            }
        }

        return val
    }

    getTaxAmount(): string {
        var taxExemptionReason = undefined
        if (this.rawItemValue.product !== undefined && this.rawItemValue.product !== null) {
            if (this.rawItemValue.product.taxExemptionReason !== undefined && this.rawItemValue.product.taxExemptionReason !== null) {
                if (this.rawItemValue.product.taxExemptionReason !== '' && !this.getIsContainsTax(this.rawItemValue)) {
                    taxExemptionReason = this.rawItemValue.product.taxExemptionReason
                }
            }
        }

        if (IndiaTaxParser.getIsIndiaDefaultTaxTemplates(this.data.templateId) && IndiaTaxParser.getIsContainsIndiaTax(this.data)) {
            //this
            if (taxExemptionReason !== undefined && !this.getIsContainsTax(this.rawItemValue)) {
                return ''
            }
            else if (IndiaTaxParser.getIsIGST(this.data)) {
                var igstAmt = this.getIndiaTaxAmount(this.rawItemValue.igstAmt, this.rawItemValue.igst)
                return Utility.toCurrencyFormat((igstAmt ? igstAmt : 0), this.currency);
            }
            else {
                var cgstAmt = this.getIndiaTaxAmount(this.rawItemValue.cgstAmt, this.rawItemValue.cgst)
                var sgstAmt = this.getIndiaTaxAmount(this.rawItemValue.sgstAmt, this.rawItemValue.sgst)
                const totalAmount = (cgstAmt ? cgstAmt : 0) + (sgstAmt ? sgstAmt : 0);
                return Utility.toCurrencyFormat(totalAmount, this.currency);
            }
        }
        return Utility.toCurrencyFormat(this.tax, this.currency)
    }

    getTaxName(): string {
        var taxExemptionReason = undefined
        if (this.rawItemValue.product !== undefined && this.rawItemValue.product !== null) {
            if (this.rawItemValue.product.taxExemptionReason !== undefined && this.rawItemValue.product.taxExemptionReason !== null) {
                if (this.rawItemValue.product.taxExemptionReason !== '' && !this.getIsContainsTax(this.rawItemValue)) {
                    taxExemptionReason = this.rawItemValue.product.taxExemptionReason
                }
            }
        }

        if (this.rawItemValue.gstType && this.rawItemValue.gstType !== null) {
            let taxName = this.rawItemValue.tax?.name ?? ''
            if (this.rawItemValue.gstType === 'EXEMPT') {
                return taxName +  "\nGST - EXEMPT "
            }
            return taxName
        }
        if (IndiaTaxParser.getIsIndiaDefaultTaxTemplates(this.data.templateId)) {
            //this
            if (taxExemptionReason !== undefined && !this.getIsContainsTax(this.rawItemValue)) {
                return "Non Taxable: " + taxExemptionReason
            }
            else if (IndiaTaxParser.getIsContainsIndiaTax(this.data)) {
                if (IndiaTaxParser.getIsIGST(this.data)) {
                    var igstAmt = this.getIndiaTaxAmount(this.rawItemValue.igstAmt, this.rawItemValue.igst)
                    return "<b>IGST: </b>" + Utility.toCurrencyFormat(igstAmt, this.currency) + ' (' + this.rawItemValue.igstRate + '%)'
                }
                else {
                    var cgstAmt = this.getIndiaTaxAmount(this.rawItemValue.cgstAmt, this.rawItemValue.cgst)
                    var sgstAmt = this.getIndiaTaxAmount(this.rawItemValue.sgstAmt, this.rawItemValue.sgst)
                    return "<b>CGST: </b>" + Utility.toCurrencyFormat(cgstAmt, this.currency) + ' (' + this.rawItemValue.cgstRate + '%)<br/>' +
                        "<b>SGST: </b>" + Utility.toCurrencyFormat(sgstAmt, this.currency) + ' (' + this.rawItemValue.sgstRate + '%)'
                }
            }
            else {
                if (this.rawItemValue.taxInfo !== undefined && this.rawItemValue.taxInfo !== null) {
                    if (this.rawItemValue.taxInfo.name !== undefined && this.rawItemValue.taxInfo.name !== null) {
                        return this.rawItemValue.taxInfo.name
                    }
                }
            }
        }
        else {
            if (this.rawItemValue.taxInfo !== undefined && this.rawItemValue.taxInfo !== null) {
                if (this.rawItemValue.taxInfo.name !== undefined && this.rawItemValue.taxInfo.name !== null) {
                    return this.rawItemValue.taxInfo.name
                }
            }
            if (this.rawItemValue.taxRate && this.rawItemValue.taxRate !== null) {
                return this.rawItemValue.taxRate + '%'
            }
        }
        return Utility.toCurrencyFormat(this.tax, this.currency)
    }

    getIsContainsTax(rawItemValue: any) {
        var igstAmt = this.getIndiaTaxAmount(rawItemValue.igstAmt, rawItemValue.igst)
        var cgstAmt = this.getIndiaTaxAmount(rawItemValue.cgstAmt, rawItemValue.cgst)
        var sgstAmt = this.getIndiaTaxAmount(rawItemValue.sgstAmt, rawItemValue.sgst)
        var taxAmount = rawItemValue.taxAmount
        return !(this.getIsZeroAmount(igstAmt)
            && this.getIsZeroAmount(cgstAmt)
            && this.getIsZeroAmount(sgstAmt)
            && this.getIsZeroAmount(taxAmount))
    }

    getIsZeroAmount(amount: number | undefined) {
        if (amount === undefined || amount === null) {
            return true
        }

        return amount <= 0
    }

    getIndiaTaxAmount(taxAmt: number, tax: any) {
        var amt = IndiaTaxParser.getDefaultAmtValue(taxAmt)
        if (amt !== undefined) {
            if (isNaN(amt)) {
                amt = tax
            }
        }
        else {
            amt = tax
        }
        return amt
    }

    getDiscount() {
        if (this.rawItemValue.isDiscountInPercent !== undefined && this.rawItemValue.isDiscountInPercent !== null) {
            if (this.rawItemValue.isDiscountInPercent) {
                return Utility.toCurrencyFormat(this.rawItemValue.discountPercent, '') + '%'
            }
        }

        return Utility.toCurrencyFormat(this.discount, this.currency)
    }

    getAmount(): string {
        if (this.rawItemValue.amount !== undefined && this.rawItemValue.amount !== null) {
            return Utility.toCurrencyFormat(this.rawItemValue.amount, this.currency)
        }
        return Utility.toCurrencyFormat(parseFloat(this.quantity) * parseFloat(this.unitPrice), this.currency)
    }

    getAmountBeforeTax(): string {
        var value = Utility.toCurrencyFormat('0', this.currency)
        if (this.preTaxAmount !== undefined && this.preTaxAmount !== undefined) {
            return Utility.toCurrencyFormat(this.preTaxAmount, this.currency)
        }
        else if (this.amountBeforeTax !== undefined && this.amountBeforeTax !== null) {
            var formattedValue = NumberUtility.getFormattedValue(this.amountBeforeTax, AppManager.getNumberFormat(), AppManager.getDecimalScale())
            if (!isNaN(formattedValue)) {
                value = Utility.toCurrencyFormat(formattedValue, this.currency)
            }
            else {
                var amount = 0
                if (this.priceInclusiveOfTax) {
                    amount = ComponentManager.getProductTaxInclusivePrice(this.rawItemValue)
                }
                else {
                    if (this.rawItemValue.amount !== undefined && this.rawItemValue.amount !== null) {
                        amount = this.rawItemValue.amount
                    }

                    var taxAmount = 0
                    if (this.rawItemValue.taxInfo !== undefined && this.rawItemValue.taxInfo !== null) {
                        if (this.rawItemValue.taxInfo.percent !== undefined && this.rawItemValue.taxInfo.percent !== null) {
                            var taxPercent = this.rawItemValue.taxInfo.percent
                            if (taxPercent > 0) {
                                taxAmount = amount * taxPercent / 100
                            }
                        }
                    }
                    else if (this.tax !== undefined && this.tax !== null) {
                        if (!isNaN(this.tax)) {
                            taxAmount = this.tax
                        }
                    }
                    if (amount > taxAmount) {
                        amount = amount - taxAmount
                    }
                }
                formattedValue = NumberUtility.getFormattedValue(amount, AppManager.getNumberFormat(), AppManager.getDecimalScale())
                value = Utility.toCurrencyFormat(formattedValue, this.currency)
            }
        }
        return value
    }

    getProductCustomFieldByCode(code: string) {
        var customFields = this.rawItemValue.customFields
        if (customFields === undefined || customFields === null) {
            return undefined
        }

        if (customFields.length === 0) {
            return undefined
        }

        return customFields.find((x: any) => x.code === code)
    }

    getGlobalDiscountText(amount: string, percent: number, isPercent: any) {
        var value = amount
        let globalDiscountType = Utility.getProductTableConfiguration('globalDiscountType')
        
        if(globalDiscountType === undefined) {
            globalDiscountType = 'SHOW_ALL'
        }
        
        if (globalDiscountType === 'SHOW_ALL') {
            if (isPercent !== undefined && isPercent !== null) {
                if (isPercent) {
                    value = amount + ' (' + percent + '%)'
                }
            }
        }
        else if (globalDiscountType === 'PERCENTAGE') {
            if(isPercent !== undefined && isPercent !== null) {
                if(isPercent) {
                    value = percent + '%'
                }
            }
        }
        else if (globalDiscountType === 'AMOUNT') {
            value = amount
        }

        return  value
    }

    getGlobalDiscountById(id: string) {
        let value = ''

        if(id !== undefined) {
            if(this.globalDiscount !== undefined && this.globalDiscount !== null) {
                if(this.globalDiscount.length > 0) {
                    var globalDiscount = this.globalDiscount.find((x: any) => x.id === id)
                    if(globalDiscount) {
                        value = this.getGlobalDiscountText(Utility.toCurrencyFormat(globalDiscount.amount, this.currency), globalDiscount.percent, globalDiscount.isPercent)
                    }
                }
            }
        }
        return value
    }

    getCombinedGlobalDiscount() {
        var globalDiscountText = ''

        var list: any = []
        if (this.globalDiscount !== undefined && this.globalDiscount !== null) {
            if (this.globalDiscount.length > 0) {
                this.globalDiscount.forEach((element: any) => {
                    let value = this.getGlobalDiscountText(Utility.toCurrencyFormat(element.amount, this.currency), element.percent, element.isPercent)
                    list.push(element.name + ' - ' + value)
                });

                if (list.length > 0) {
                    globalDiscountText = list.join('<br/>')
                }
            }
        }

        return globalDiscountText
    }

    toString(tableConfig?: any) {
        var sortedHeader = getSortedHeader(tableConfig);
        const isCogsColumn = getIsInvoice(this.data?.type) || getIsSalesOrder(this.data?.type);
        if (sortedHeader.length > 0) {
            var sortedValue: string[] = []
            sortedHeader.forEach(element => {
                var footerList = TableFooterUtility.getFooterListByType('product')
                if (isCogsColumn) {
                    var index = footerList.indexOf(TableColumnPopupType.totalAmount);
                    footerList.splice(index !== -1 ? index + 1 : 5, 0, TableColumnPopupType.cogs);
                }
                if (!footerList.includes(element.type)) {
                    sortedValue.push(this.getValueByType(element))
                }
            });
            return sortedValue
        }
        return this.getDefaultList()
    }

    getValueByType(element: any) {
        const type = element.type
        switch (type) {
            case TableColumnPopupType.lineNumber:
                return this.lineNumber
            case TableColumnPopupType.code:
                return getLocalisedText(this.code)
            case TableColumnPopupType.image:
                return this.image
            case TableColumnPopupType.productName:
                return getLocalisedText(this.name) + this.getProductHSN(this.rawItemValue)
            case TableColumnPopupType.description:
                return getLocalisedText(this.description)
            case TableColumnPopupType.productNameWithDescription:
                return '<b>' + getLocalisedText(this.name) + '</b> \n' + getLocalisedText(this.description) + this.getProductHSN(this.rawItemValue)
            case TableColumnPopupType.hsn:
                return this.hsn ? this.hsn : ''
            case TableColumnPopupType.productCustomField:
                if (element.code !== undefined) {
                    var value = ''
                    var field = this.getProductCustomFieldByCode(element.code)
                    if (field !== undefined) {
                        var cfType = element.cfType;
                        if (cfType === undefined) {
                            var customField = CustomFieldManager.getProductCustomFields().find((cf) => cf.code === element.code);
                            if (customField && customField.fieldType) {
                                cfType = customField.fieldType;
                            }
                        }

                        switch (cfType) {
                            case "DATE":
                                value = getDateString(getDateFrom(field.value, DateFormat.MM_DD_YYYY_SLASH), this.data.dateFormat) || field.value;
                                break;
                            case "NUMBER":
                                if (element.showAsCurrency && !isNaN(parseFloat(field.value))) {
                                    value = Utility.toCurrencyFormat(parseFloat(field.value) * 1, this.currency)
                                } else {
                                    value = field.value
                                }
                                break;
                            default:
                                value = field.value
                                break;
                        }
                    }
                    return value
                }
                return this.getCombineProductCustomField()
            case TableColumnPopupType.quantity:
                return Utility.toDecimalFormat(parseFloat(this.quantity), TemplateSettingsManager.getQuantityDecimalScale())
            case TableColumnPopupType.uom:
                return this.uom ? this.uom : ''
            case TableColumnPopupType.quantityWithUom:
                return this.quantityWithUom ? this.quantityWithUom : ''
            case TableColumnPopupType.serialBatch:
                return this.serialBatch ? this.serialBatch : ''
            case TableColumnPopupType.serialNumber:
                return this.serialNumber ? this.serialNumber : ''
            case TableColumnPopupType.batchNumber:
                return this.batchNumber ? this.batchNumber : ''
            case TableColumnPopupType.manufacturingDate:
                return this.manufacturingDate ? this.manufacturingDate : ''
            case TableColumnPopupType.expiryDate:
                return this.expiryDate ? this.expiryDate : ''
            case TableColumnPopupType.unitPrice:
                return Utility.toCurrencyFormat(parseFloat(this.unitPrice) * 1, this.currency)
            case TableColumnPopupType.productDiscount:
                return this.getDiscount()
            case TableColumnPopupType.productTax:
                return this.getTaxName()
            case TableColumnPopupType.productTaxAmount:
                return this.getTaxAmount()
            case TableColumnPopupType.productUnitPriceAfterDiscount:
                return Utility.toCurrencyFormat(this.unitPriceAfterDiscount * 1, this.currency)
            case TableColumnPopupType.productDiscountPerUnit:
                return Utility.toCurrencyFormat(this.discountPerUnit * 1, this.currency)
            case TableColumnPopupType.amountBeforeTax:
                return this.getAmountBeforeTax()
            case TableColumnPopupType.amount:
                return this.getAmount()
                // return this.priceInclusiveOfTax ?
                //     Utility.toCurrencyFormat(ComponentManager.getProductTaxInclusivePrice(this.rawItemValue), this.currency)
                //     : this.getAmount()
            case TableColumnPopupType.productGlobalDiscount:
                if(element.globalDiscountAccountCode !== undefined) {
                    return this.getGlobalDiscountById(element.globalDiscountAccountCode)
                }
                return this.getCombinedGlobalDiscount()
            case TableColumnPopupType.expectedDeliveryDate:
                return this.expectedDeliveryDate
            case TableColumnPopupType.availableQuantity:
                return Utility.toDecimalFormat(parseFloat(this.availableQuantity || 0), TemplateSettingsManager.getQuantityDecimalScale())
            case TableColumnPopupType.qtyConvertedToWo:
                return Utility.toDecimalFormat(parseFloat(this.qtyConvertedToWo), TemplateSettingsManager.getQuantityDecimalScale())
            case TableColumnPopupType.serialBatchCustomFields:
                return CustomFieldManager.getCombineSerialBatchCustomField(this.rawItemValue);
            default:
                return ''
        }
    }

    getDefaultList() {
        return [
            this.lineNumber,
            getLocalisedText(this.code),
            this.image,
            getLocalisedText(this.name),
            getLocalisedText(this.description),
            '<b>' + getLocalisedText(this.name) + '</b> \n' + getLocalisedText(this.description) + this.getProductHSN(this.rawItemValue),
            this.hsn,
            this.productCustomField,
            Utility.toDecimalFormat(parseFloat(this.quantity), TemplateSettingsManager.getQuantityDecimalScale()),
            this.uom ? this.uom : '',
            this.quantityWithUom,
            this.serialBatch,
            this.serialNumber,
            this.batchNumber,
            this.manufacturingDate,
            this.expiryDate,
            Utility.toCurrencyFormat(parseFloat(this.unitPrice) * 1, this.currency),
            this.getDiscount(),
            this.getTaxName(),
            this.getTaxAmount(),
            this.getAmountBeforeTax(),
            this.priceInclusiveOfTax ?
                Utility.toCurrencyFormat(ComponentManager.getProductTaxInclusivePrice(this.rawItemValue), this.currency)
                : this.getAmount(),
            this.availableQuantity,
        ]
    }
}
