import React, { Component } from 'react';
import '../../index.css';
import { EditorInfoType, FONT_SIZE, NEGATIVE_PAGE_PADDING } from '../../Constants/Constants';
import DKCustomFieldSection from '../../DKUILibrary/DKCustomFieldSection';
import DKEInvoiceSection, { eInvoiceSectionPosition } from '../../DKUILibrary/DKEInvoiceSection';
import DKSignatureSection from '../../DKUILibrary/DKSignatureSection';
import { AddressType } from '../../Helper/AddressParser';
import ComponentManager from '../../Manager/ComponentManager';
import DocumentManager from '../../Manager/DocumentManager';
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from '../../Utilities/HTMLTag';
import Utility, { getFontSizeClass, getCapitalized, getPageHeight, getVW, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry, getIsDocBuilderApp } from "../../Utilities/Utility";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import './css/Templates.css';
import DKComponentListTable from '../../DKUILibrary/DKComponentListTable';
import DKApprovalSection from '../../DKUILibrary/DKApprovalSection';

export default class DocumentTemplate25 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30)  }}>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    {this.getSideDesign()}
                    {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(50, this.state.data.printScale))}
                    {this.getCompanyDetailsSection()}
                    {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(getIsDocBuilderApp() ? 110 : 90, this.state.data.printScale))}
                    {this.getDocumentDetailsListSection()}
                    {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                    {ComponentManager.addDivider({ marginLeft: '5%', width: '90%', backgroundColor: '#000000', height: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)) })}
                    {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(10, this.state.data.printScale))}
                    {this.getBillToShipToAddressSection()}
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addDivider({ marginLeft: '5%', width: '90%', backgroundColor: '#000000', height: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)) })}
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(5)}
                    <div className="RowDiv" style={{ marginLeft: '5%', width: '90%', alignItems: 'flex-start' }}>
                        <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} />
                    </div>
                    {ComponentManager.addVerticalSpace(5)}
                    {ComponentManager.addDivider({ marginLeft: '5%', width: '90%', backgroundColor: '#000000', height: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)) })}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data) || Utility.getIsShowPlaceOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(10)}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                    {ComponentManager.addVerticalSpace(5)}
                    {
                        TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                        <div className="RowDiv" style={{ marginLeft: '5%', width: '90%', alignItems: 'flex-start' }}>
                            <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                            {ComponentManager.addVerticalSpace(5)}
                        </div>
                    }
                    {this.getProductTableSection()}
                    {this.getTableSection()}
                    {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsShowExchangeRate(this.state.data) && this.getExchangeRateTable(this.getTableStyle(), this.state.data)}
                    {this.getTransactionDocumentTable(this.getTableStyle(), this.state.data)}
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addDivider({ marginLeft: '5%', width: '90%', backgroundColor: '#000000', height: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)) })}
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(5)}
                    <div className="RowDiv" style={{ marginLeft: '5%', width: '90%', alignItems: 'flex-start' }}>
                        <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'}/>
                    </div>
                    {this.state.data.showSignature && ComponentManager.addVerticalSpace(20)}
                    {this.state.data.showSignature && ComponentManager.addDivider({ marginLeft: '5%', width: '90%', backgroundColor: '#000000', height: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)) })}
                    <div className="RowDiv" style={{ marginLeft: '5%', width: '90%', alignItems: 'flex-start' }}>
                        {Utility.getIsIncludesBomProductTable() && <DKComponentListTable data={this.state.data} />}
                    </div>
                    <div id={HTMLTag.SIGNATURE} className="break-inside-avoid">
                        {ComponentManager.addVerticalSpace(10)}
                        {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                    </div>
                </div>
                <div className='parent-width' style={{ width: '100%' }}>
                    {this.getFooterSection()}
                    {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} />}
                </div>
                {Utility.getIsShowEInvoiceCorrectPosition(eInvoiceSectionPosition.bottom) && this.getEInvoiceBottomSection()}
            </div>
        );
    }

    /////// Side Design ///////////
    getSideDesign() {
        return (
            <div
                style={{
                    position: 'absolute',
                    width: getVW(Utility.convertToPrintScale(getIsDocBuilderApp() ? 50 : 35, this.state.data.printScale)),
                    marginLeft: getIsDocBuilderApp() ? getVW(`${Utility.convertToPrintScale(Number(NEGATIVE_PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`, true) : 0,
                    marginTop: Utility.getIsShowEInvoiceCorrectPosition(eInvoiceSectionPosition.top) ? undefined : getIsDocBuilderApp() ? getVW(`${Utility.convertToPrintScale(Number(NEGATIVE_PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`, true) : 0,
                    backgroundColor: this.state.data.themeColor,
                    height: '100%' }}>
            </div>
        )
    }

    /////// Company Details Section ///////////
    getCompanyDetailsSection() {
        return (
            <div className="ColumnDiv"
                style={{
                    marginLeft: '5%',
                    width: '90%',
                    alignItems: Utility.getIsArabicLang() ? 'flex-end': 'flex-start'
                }}>
                {this.state.data.showCompanyName && <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        marginTop: getVW(Utility.convertToPrintScale(15, this.state.data.printScale)),
                        color: this.state.data.themeColor,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xxLarge }, this.state.data.printScale),
                    }}>
                        {getLocalisedText(Utility.getCompanyName(this.state.data))}
                </text>}
                {this.state.data.showFrom && <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                        overflow: 'hidden',
                        color: "#ABABAB",
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale),
                    }}
                    dangerouslySetInnerHTML={{__html: getLocalisedText(Utility.getCompanyAddress(this.state.data))}}>
                </div>}
            </div>
        )
    }

    /////// Document Details Section ///////////
    getDocumentDetailsListSection() {
        return (
            <div className="ColumnDiv" style={{ marginLeft: '5%', width: '90%', alignItems: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start' }}>
                {this.get1stDocumentDetailsSection()}
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        color: '#000000',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xxxLarge }, this.state.data.printScale),
                    }}>
                        {getCapitalized(getLocalisedText(this.state.data.documentType))}
                </text>
                {this.getDocumentDetailsSection()}
            </div>
        )
    }

    get1stDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        var value = ''
        if (documentDetailList[0]?.key !== undefined) {
            value = this.state.data[documentDetailList[0]?.key]
        }
        else if (documentDetailList[0]?.customFieldValue !== undefined) {
            value = documentDetailList[0]?.customFieldValue
        }

        return <text className={"docFontStyleBold" + getFontSizeClass()}
            style={{
                color: this.state.data.themeColor,
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale),
            }}>
            {documentDetailList[0]?.label + " : " + value}
        </text>
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        var newDocumentDetailList = documentDetailList.filter(x => x.type !== documentDetailList[0].type)
        var multiArray = []
        var arraySize = 3
        var rowCount = Math.ceil(newDocumentDetailList.length / arraySize)

        for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
            var startIndex = rowIndex * arraySize
            var columnList = []
            for (let index = startIndex; index < startIndex + arraySize; index++) {
                var data = newDocumentDetailList[index]
                if (data !== undefined && data !== null) {
                    columnList.push(data)
                }
                else {
                    columnList.push(undefined)
                }
            }
            multiArray.push(columnList)
        }

        return multiArray.map(rowElement => {
            var colElementDiv = rowElement.map((colElement, index) => {
                if (colElement === undefined) {
                    return <></>
                }

                var value = ''
                if (colElement.key !== undefined) {
                    value = this.state.data[colElement.key]
                }
                else if (colElement.customFieldValue !== undefined) {
                    value = colElement.customFieldValue
                }

                if(index === 0) {
                    return <div className={"RowDiv docFontStyleBold" + getFontSizeClass()}
                        style={{
                            textAlign: 'right',
                            overflow: 'hidden',
                            color: "#000000",
                            padding: getVW(Utility.convertToPrintScale(3, this.state.data.printScale)),
                            width: 'auto',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale),
                        }}>
                        <div>
                            {colElement.label + " : "}
                        </div>
                        <div>
                            {value}
                        </div>
                    </div>
                }
                else {
                    return <div className={"RowDiv docFontStyleBold" + getFontSizeClass()}
                        style={{
                            width: 'auto',
                            textAlign: 'right',
                            overflow: 'hidden',
                            color: "#000000",
                            marginLeft: (this.state.data.showDueDate && this.state.data.showStartDate) ? getVW(10) : null,
                            padding: getVW(Utility.convertToPrintScale(3, this.state.data.printScale)),
                            paddingLeft: (this.state.data.showDueDate && this.state.data.showStartDate) ? getVW(10) : null,
                            borderLeftWidth: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)),
                            borderLeftStyle: 'solid',
                            borderLeftColor: (this.state.data.showDueDate && this.state.data.showStartDate) ? this.state.data.themeColor : 'transparent',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale),
                        }}>
                        <div>
                            {colElement.label + " : "}
                        </div>
                        <div>
                            {value}
                        </div>
                    </div>
                }
            });


            return <div className="RowDiv" style={{
                justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start'
            }}>
                {colElementDiv}
                </div>
        });
    }


    /////// Bill to Ship To Section ///////////
    getBillToShipToAddressSection() {
        return (
            <div className="RowDiv" style={{ marginLeft: '5%', width: '90%', alignItems: 'flex-start' }}>
                <div style={{ width: '50%' }}>
                    {this.state.data.showClientBillToAddress && this.getBillingAddessSection()}
                </div>
                <div style={{ width: '50%' }}>
                    {this.state.data.showClientShipToAddress && this.getShippingAddressSection()}
                </div>
            </div>
        )
    }

    getShippingAddressSection() {
        return <div className="RowDiv parent-width">
            <div className='parent-width' style={{
                borderLeftColor: '#000000',
                borderLeftWidth: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)), borderLeftStyle: 'solid'
            }}>
                <div className="ColumnDiv" style={{
                    width: Utility.getIsArabicLang() ? '95%' : '100%'
                }}>
                    {this.getShippingAddress()}
                </div>
            </div>
        </div>;
    }

    getBillingAddessSection() {
        return <div className="RowDiv parent-width">
            <div className='parent-width' style={{
                borderLeftColor: this.state.data.themeColor,
                borderLeftWidth: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)), borderLeftStyle: 'solid',
            }}>
                <div className="ColumnDiv" style={{
                    width: Utility.getIsArabicLang() ? '95%' : '100%'
                }}>
                    {this.getBillingAddress()}
                </div>
            </div>
        </div>;
    }

    getShippingAddress() {
        return <>
            <text className={"RowDiv docFontStyleBold" + getFontSizeClass()}
                style={{
                    textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                    marginLeft: Utility.getIsArabicLang() ? 0 : getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                    paddingRight: Utility.getIsArabicLang() ? getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) : 0,
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium, ownerType: Utility.getOwnerType(this.state.data, AddressType.shipTo) }, this.state.data.printScale)
                }}>
                    {getLocalisedText(this.state.data.shipToTitle).toUpperCase() + ':'}
            </text>
            {ComponentManager.addVerticalSpace(5)}
            <div className={"RowDiv docFontStyle" + getFontSizeClass()}
                style={{
                    display: 'block',
                    overflow: 'hidden',
                    textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                    marginLeft: Utility.getIsArabicLang() ? 0 : getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                    paddingRight: Utility.getIsArabicLang() ? getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) : 0,
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.shipTo) }, this.state.data.printScale)
                }}
                    dangerouslySetInnerHTML={{__html: getLocalisedText(Utility.getClientShipToAddress(this.state.data))}}>
            </div>
        </>;
    }

    getBillingAddress() {
        return <>
            <div className={"RowDiv docFontStyleBold" + getFontSizeClass()}
                style={{
                    textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                    marginLeft: Utility.getIsArabicLang() ? 0 : getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                    paddingRight: Utility.getIsArabicLang() ? getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) : 0,
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo) }, this.state.data.printScale),
                }}>
                    {getLocalisedText(this.state.data.billToTitle).toUpperCase() + ':'}
            </div>
            {ComponentManager.addVerticalSpace(5)}
            <div className={"RowDiv docFontStyle" + getFontSizeClass()}
                style={{
                    display: 'block',
                    overflow: 'hidden',
                    textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                    marginLeft: Utility.getIsArabicLang() ? 0 : getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                    paddingRight: Utility.getIsArabicLang() ? getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) : 0,
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo) }, this.state.data.printScale),
                }}
                dangerouslySetInnerHTML={{__html: getLocalisedText(Utility.getClientBillToAddress(this.state.data))}}>
            </div>
        </>;
    }

    /////// Table Section ///////////
    getTableSection() {
        return  <div className="RowDiv" style={{ marginLeft: '5%', width: '90%', alignItems: 'flex-start' }}>
                {this.getEditableDocumentTable()}
            </div>
    }

    getProductTableSection() {
        return <div className="RowDiv" style={{ marginLeft: '5%', width: '90%', alignItems: 'flex-start' }}>
                {this.getProductTable()}
                {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(20)}
            </div>
    }



    /////// Footer Section ///////////
    getFooterSection() {
        return (
            <div className="ColumnDiv" style={{ marginLeft: '5%', width: '90%', bottom: 0 }}>
                {ComponentManager.addDivider({ backgroundColor: '#000000', height: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)), width: '100%' })}
                {ComponentManager.addVerticalSpace(20)}
                {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />}
                <div id={HTMLTag.TERMS_NOTE_PREFIX} className="RowDiv" style={{alignItems: 'flex-start'}}>
                    {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) &&
                        ComponentManager.getTermsAndConditionOrNotesSection(
                            Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                            this.state.data.termsAndCondition,
                            "100%",
                            undefined,
                            undefined,
                            undefined,
                            getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                            this.state.data.printScale,
                            Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                        )}
                    {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && (this.state.data.showNotes && this.state.data.notes)) && ComponentManager.addHorizontalSpace(20)}
                    {(this.state.data.showNotes && this.state.data.notes) &&
                        ComponentManager.getTermsAndConditionOrNotesSection(
                            Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                            this.state.data.notes,
                            "100%",
                            undefined,
                            undefined,
                            undefined,
                            getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                            this.state.data.printScale,
                            Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes)
                        )}
                </div>
            </div>
        )
    }


    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = "#000000";
        style.footerTextColor = 'white';
        style.headerBackgroundColor = "transparent";
        style.headerBorderColor = "transparent";
        style.itemBorderColor = "transparent";
        style.footerBackgroundColor = this.state.data.themeColor;
        style.footerBorderColor = this.state.data.themeColor;
        return style;
    }

    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 2
        style.columnWidths = ['50%', '50%']
        return style;
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div className="RowDiv" style={{ marginLeft: '5%', width: '90%', alignItems: 'flex-start' }}>
                <div style={{ width: '50%' }}>
                    { this.getPlaceOfSupply()}
                </div>
            </div>
        )
    }
    getPlaceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '100%'
            }}>
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('place_of_supply'),
                    this.state.data.placeOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                    true,
                    this.state.data.printScale
                )}
            </div>
        </div>;
    }

    getSourceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getSourceOfSupply()}

            </div>
        )
    }

    getSourceOfSupply() {
        return <div className='RowDiv' style={{ width: '100%' , marginLeft: '50px'}}>
            <div className='ColumnDiv' style={{
                width: '48%'
            }}>
                {Utility.getIsShowSourceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('source_of_supply'),
                    this.state.data.sourceOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                    true,
                    this.state.data.printScale
                )}
            </div>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('destination_of_supply'),
                    this.state.data.destinationOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                    true,
                    this.state.data.printScale
                )}
            </div>
        </div>;
    }

    getEInvoiceBottomSection() {
        return (
            <div className="ColumnDiv" style={{ marginLeft: '5%', width: '90%', bottom: 0 }}>
                {ComponentManager.addVerticalSpace(20)}
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
            </div>
        )
    }

    getExchangeRateTable(tableStyle, data) {
        const style = {
            ...tableStyle,
            headerBorderBottomColor: "black",
            headerBorderBottomWidth: getVW(Utility.convertToPrintScale(2, data.printScale)),
        };

        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{ marginLeft: "5%", width: "90%", justifyContent: "flex-end" }}>
                <div style={{ width: "45%" }}>
                    <div style={{ height: getVW(Utility.convertToPrintScale(2, data.printScale)), backgroundColor: 'black' }} />
                    {ComponentManager.getExchangeRateTable(style, data)}
                </div>
            </div>
        );
    }

    getTransactionDocumentTable(tableStyle, data) {
        const style = {
            ...tableStyle,
            headerBorderBottomColor: "black",
            headerBorderBottomWidth: getVW(Utility.convertToPrintScale(2, data.printScale)),
        };

        return (
            <div id={HTMLTag.TRANSACTION_DOCUMENT_TABLE} className="RowDiv" style={{ marginLeft: "5%", width: "90%", justifyContent: "flex-end" }}>
                <div style={{ width: "65%" }}>
                    <div style={{ height: getVW(Utility.convertToPrintScale(2, data.printScale)), backgroundColor: 'black' }} />
                    {ComponentManager.getTransactionDocumentTable(style, data)}
                </div>
            </div>
        );
    }
}
