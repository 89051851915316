import React, { Component } from "react";
import "../../App.css";
import "../../CommonStyles/FontStyle.css"
import TableColumnPopupRow from "./TableColumnPopupRow";
// import {getLocalisedText} from '../../Translate/LanguageManager';
// import ExpenseMapper from "../../DocumentMapper/ExpenseMapper";
// import CreditNoteMapper from "../../DocumentMapper/CreditNoteMapper";
// import Utility, { getIsAccountModule, getIsDebitOrCreditNote, getIsExpenseBill, getIsExpenseOrDeposit, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry, getIsMakePayment, getIsPackList, getIsPickList, getIsReceivePayment, getIsShipList, getIsStockAdjustment, getIsStockTransfer, getIsWorkOrder, getVW } from "../../Utilities/Utility";
import PopupHeaderComponent from "./PopupHeaderComponent";
// import TemplateSettingsManager from "../../Manager/TemplateSettingsManager";
// import { getIsSelectTotalColumn } from "../../DKUILibrary/dktable/TableUtility";
// import CustomFieldManager from "../../Manager/CustomFieldManager";
// import TableFooterUtility from "../../Utilities/TableFooterUtility";
// import LineItemsGlobalDiscountParser from "../../Helper/LineItemsGlobalDiscountParser";
// import { COLOR_LIGHTGRAY } from "../../Constants/Constants";

import {
    getLocalisedText,    
    Utility,  
    getIsAccountModule, 
    getIsDebitOrCreditNote, 
    getIsExpenseBill, 
    getIsExpenseOrDeposit, 
    getIsFulfillment, 
    getIsGoodsReceipt, 
    getIsJournalEntry, 
    getIsMakePayment, 
    getIsPackList, 
    getIsPickList, 
    getIsQCDocument,
    getIsReceivePayment, 
    getIsShipList, 
    getIsStockAdjustment, 
    getIsStockTransfer, 
    getIsWorkOrder, 
    getVW,
    TemplateSettingsManager,
    CustomFieldManager,
    COLOR_LIGHTGRAY,
    getIsSelectTotalColumn,
    TableFooterUtility,
    LineItemsGlobalDiscountParser,
    ExpenseMapper,
    CreditNoteMapper,
    Asset,
    TableColumnPopupType,
    SignatureColumnType,
    TableTotalColumnList,
    TableTotalColumnListForJournalEntry
} from 'deskera-doc-builder-lib'
import { getIsSalesReturn } from "deskera-doc-builder-lib";
import { getIsJobWorkOutOrder } from "deskera-doc-builder-lib";
import { getIsBOMModule } from "deskera-doc-builder-lib";
import { getIsStockRequest } from "deskera-doc-builder-lib";
import { getIsStockIssue } from "deskera-doc-builder-lib";
import { getIsServiceRequisition } from "deskera-doc-builder-lib";
import { getIsPurchaseRequisition } from "deskera-doc-builder-lib";
import { getIsGoodsReceiptNoteTemplate } from "deskera-doc-builder-lib";
import { getIsQCInspection } from "deskera-doc-builder-lib";
import { getIsInspectionReport } from "deskera-doc-builder-lib";
import { getIsSOACustomer } from "deskera-doc-builder-lib";
import { getIsSOAVendor } from "deskera-doc-builder-lib";
import { TableTotalColumnListForSOA } from "deskera-doc-builder-lib";
import { getIsRequestForQuotation } from "deskera-doc-builder-lib";
import { getIsPurchaseInwardQuotation } from "deskera-doc-builder-lib";
import { getIsBill } from "deskera-doc-builder-lib";
import { getIsOrder } from "deskera-doc-builder-lib";
export default class TableColumnPopup extends Component {

    startIndex = undefined
    startIndexText = undefined
    dragIndex = undefined

    constructor(props) {
        super(props);
        this.state = {
            data: this.getData(),
            signatureList: this.getSignatureList(),
            footerList: TableFooterUtility.getFooterListByDocument(this.props.documentData),
            isTotalSectionSelected: getIsSelectTotalColumn(),
            totalColumnList: this.getTotalColumnList(),
            isIncludesProductCustomField: this.getProductCustomFieldsConfiguration('isIncludesProductCustomField'),
            isCombinesProductCustomField: this.getProductCustomFieldsConfiguration('isCombinesProductCustomField'),
            isCombinesLineItemGlobalDiscountField: this.getProductCustomFieldsConfiguration('isCombinesLineItemGlobalDiscountField'),
            combinedProductCustomFieldList: CustomFieldManager.getActiveProductCustomFields(),
            isHSNSelected: this.getIsHSNSelected(),
            isAlterUOMSelected: this.getIsAlterUOMSelected(),
            isIncludesAccountCustomField: this.getAccountCustomFieldsConfiguration('isIncludesAccountCustomField'),
            isCombinesAccountCustomField: this.getAccountCustomFieldsConfiguration('isCombinesAccountCustomField'),
            combinedAccountCustomFieldList: CustomFieldManager.getActiveAccountCustomFields(),
            isGroupAdditionalChargesTax: TemplateSettingsManager.getIsGroupAdditionalChargesTax(),
            isIncludesPreferredVendorProductCustomField: this.getProductCustomFieldsConfiguration('isIncludesPreferredVendorProductCustomField'),
        };
    }

    render() {
        return (
            <div className="ShadowMedium WindowPopup" style={{ backgroundColor:'white', width: '90%', height: 'auto', maxWidth: 370, padding: 0, paddingBottom: 10, overflowY:'hidden'}}>
                <PopupHeaderComponent
                    headerText={'edit_table'}
                    cancelText={'cancel'}
                    submitText={'save'}
                    onCancelClicked={this.cancelButtonTapped}
                    onSubmitClicked={this.saveButtonTapped}
                />
                {this.getTableColumnPopup()}
            </div>
        );
    }

    getData() {
        let tableColumnOptions = this.props.data
        if (this.getIsShowProductCustomFieldOption()) {
            let data = CustomFieldManager.getActiveProductCustomFieldsTableOptions(this.props.data)
            tableColumnOptions = data
        }
        else if (getIsWorkOrder(this.props.documentData.type) || getIsStockTransfer(this.props.documentData.type) || getIsStockAdjustment(this.props.documentData.type)) {
            tableColumnOptions = CustomFieldManager.getActiveProductCustomFieldsTableOptions(this.props.data)
        }
        else if (this.getIsShowAccountCustomFieldOption()) {
            tableColumnOptions = CustomFieldManager.getActiveAccountCustomFieldsTableOptions(this.props.data)
        }
        else if(this.getIsProductAccountTable()) {
            tableColumnOptions = Utility.getTableColumnForAccountProduct()
        }

        tableColumnOptions = this.addMissingSerialBatchCustomField(tableColumnOptions)
        return tableColumnOptions
    }

    addMissingSerialBatchCustomField(tableColumnPopup) {
        const lineItems = this.props.documentData.lineItems ?? []

        if(lineItems.length > 0) {
            let isContainsSerialBatchCustomFields = true

            if(tableColumnPopup.length > 0) {
                isContainsSerialBatchCustomFields = tableColumnPopup.map(x => x.type).includes(TableColumnPopupType.serialBatchCustomFields)
            }

            if(!isContainsSerialBatchCustomFields) {
                const serialBatchLineItems = lineItems.filter(x => x.serialBatchCustomFieldType !== undefined && x.serialBatchCustomFieldType !== 'NONE')
                if (serialBatchLineItems.length > 0) {
                    var serialBatchCustomFields = { index: tableColumnPopup.length, "type": TableColumnPopupType.serialBatchCustomFields, "isSelected": false, "name": "serial_batch_custom_fields" };
                    tableColumnPopup.push(serialBatchCustomFields)
                } 
            }
            
        }

        return tableColumnPopup
    }

    getIsProductAccountTable() {
        var isProductAccountTable = false
        if(this.props.tableType !== undefined && this.props.tableType !== null) {
            if(this.props.tableType === 'product') {
                isProductAccountTable = true
            }
        }

        return isProductAccountTable
    }

    getSignatureList() {
        if(this.getIsContainsSignatureSection()) {
            return TemplateSettingsManager.defaultTemplateSettings.signatureInfo
        }
        return []
    }

    getTotalColumnList() {
        if (TemplateSettingsManager.defaultTemplateSettings.totalColumnSection !== undefined) {
            if (TemplateSettingsManager.defaultTemplateSettings.totalColumnSection.items !== undefined &&
                TemplateSettingsManager.defaultTemplateSettings.totalColumnSection.items !== null) {
                if (TemplateSettingsManager.defaultTemplateSettings.totalColumnSection.items.length > 0) {
                    var columnList = TemplateSettingsManager.defaultTemplateSettings.totalColumnSection.items
                    var item = columnList[0]
                    var newList = []
                    if (typeof item === 'string' || item instanceof String) {
                        columnList.forEach(element => {
                            var newItem = {
                                type: element,
                                code: undefined
                            }
                            newList.push(newItem)
                        });
                    }
                    else {
                        newList = columnList
                    }

                    return newList
                }
            }
        }
        return []
    }

    getIsHSNSelected() {
        return TemplateSettingsManager.getIsHSNSelected(this.props.data)
    }

    getIsAlterUOMSelected() {
        return TemplateSettingsManager.getIsAlterUOMSelected(this.props.data)
    }

    getTableColumnPopup() {
        return (
            <div>
                <div
                    className="ColumnDiv"
                    style={{
                        backgroundColor: 'white',
                        borderRadius: getVW(2),
                        width: '100%',
                        height: this.getScrollingHeightRatioByType(),
                        alignItems: 'center',
                        overflowY: 'scroll',
                        overflowX: 'hidden'
                    }}
                >
                    {this.getLineItemOptionSection()}
                    {this.getIsProductModule() && !TemplateSettingsManager.getRepeatableHeaderFooterIsVisible() && this.getTotalSection()}
                    {this.getIsContainFooter(this.getFooterData()) && this.getFooterItemOptionSection()}
                    {this.getIsContainsSignatureSection() && this.getSignatureItemOptionSection()}
                    {(getIsJournalEntry(this.props.documentData.type) ||
                        getIsSOACustomer(this.props.documentData.type) || 
                        getIsSOAVendor(this.props.documentData.type))
                        && !TemplateSettingsManager.getRepeatableHeaderFooterIsVisible() 
                        && this.getTotalSection()}
                </div>
            </div>
        );
    }

    getScrollingHeightRatioByType() {
        var defaultThreshold = 787 //default without total row  866 // for product related
        var innerHeight = window.innerHeight

        if(getIsAccountModule(undefined, this.props.documentData.type)) {
            if(getIsExpenseOrDeposit(this.props.documentData.type)) {
                defaultThreshold = 480
            }
            else if (getIsDebitOrCreditNote(this.props.documentData.type)) {
                defaultThreshold = 440
            }
        }
        else if(getIsFulfillment(this.props.documentData.type)) {
            defaultThreshold = 580
        }
        else if (getIsGoodsReceipt(this.props.documentData.type)) {
            defaultThreshold = 580
        }
        else if (getIsMakePayment(this.props.documentData.type) || getIsReceivePayment(this.props.documentData.type)) {
            defaultThreshold = 440
        }
        else if (getIsJournalEntry(this.props.documentData.type)) {
            defaultThreshold = 440
            defaultThreshold = this.getAdditionalHeightForTotalRow(defaultThreshold, TableTotalColumnListForJournalEntry)
        }
        else if (getIsPickList(this.props.documentData.type)) {
            defaultThreshold = 440
        }
        else if (getIsPackList(this.props.documentData.type)) {
            defaultThreshold = 333
        }
        else if (getIsShipList(this.props.documentData.type)) {
            defaultThreshold = 247
        }
        else if (getIsQCDocument(this.props.documentData.type)) {
            defaultThreshold = 165
        }
        else if (getIsQCInspection(this.props.documentData.type)) {
            defaultThreshold = 255
        }
        else if (getIsInspectionReport(this.props.documentData.type)) {
            defaultThreshold = 0
        }
        else if (getIsSalesReturn(this.props.documentData.type)) {
            defaultThreshold = 420
        }
        else if (getIsBOMModule(this.props.documentData.type)) {
            defaultThreshold = 435
        }
        else if (getIsStockRequest(this.props.documentData.type)) {
            defaultThreshold = 570
        }
        else if (getIsStockIssue(this.props.documentData.type)) {
            defaultThreshold = 680
        }
        else if (getIsSOACustomer(this.props.documentData.type) || getIsSOAVendor(this.props.documentData.type)) {
            defaultThreshold = 605
        }
        else if(!this.getIsProductModule()) { // for expense bill
            defaultThreshold = 536
        }
        else {
            defaultThreshold = this.getAdditionalHeightForTotalRow(defaultThreshold, TableTotalColumnList)
        }

        if(defaultThreshold > innerHeight) {
            return innerHeight * 0.8
        }
        return defaultThreshold
    }

    getAdditionalHeightForTotalRow(defaultThreshold, totalColumnList) {
        if (this.state.isTotalSectionSelected) {
            //each row is 26.28px
            var selectedList = this.state.data.filter(x => x.isSelected)
            var itemCount = 0
            if (selectedList !== undefined && selectedList !== null) {
                if (selectedList.length > 0) {
                    selectedList.forEach(element => {
                        if (totalColumnList.includes(element.type)) {
                            itemCount = itemCount + 1
                        }
                    });
                }
            }

            var newArraySize = 2
            defaultThreshold = defaultThreshold + Math.ceil(itemCount / newArraySize) * 26.28
        }
        if (this.state.isCombinesAccountCustomField || this.state.isIncludesAccountCustomField) {
            defaultThreshold = defaultThreshold + 45
        }
        return defaultThreshold
    }

    getTableColumnCheckboxConfiguration(message, key) {
        return <div className='RowDiv'
            style={{
                width: '100%',
                paddingLeft: 13,
                paddingRight: 13,
                boxSizing: 'border-box',
                position: 'relative'
            }}
        >
            <input
                type="checkbox"
                checked={this.state[key]}
                id={'product_column_popup_product_custom_field_checkbox'}
                onClick={() => {
                    this.productCheckboxConfigurationOnClick(key)
                }}
                disabled={this.getTableColumnCheckboxConfigurationDisable(key)}
            />
            <div
                className='TextField ListPicker'
                style={{
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    pointerEvents: 'none'
                }}>
                {getLocalisedText(message)}
            </div>
            {this.renderResetButton(key)}
        </div>
    }

    renderResetButton(key) {
        var isShowReset = this.getIsShowResetButton(key)
        if(!isShowReset) {
            return <></>
        }
        return <div className="ColumnDiv"
            style={{
                width: 30,
                height: 30,
                cursor: 'pointer',
                backgroundColor: COLOR_LIGHTGRAY,
                borderRadius: '50%',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 4,
            }}
            id={'row_reset_' + key}
            onClick={() => {
                this.onResetClick(key)
            }}
        >
            <img src={Asset.icon.ic_refresh_black} alt="reset" style={{ width: 16, pointerEvents: 'none' }} />
        </div>
    }

    getTableColumnCheckboxConfigurationDisable(key) {
        if (key === 'isIncludesProductCustomField' || key === 'isCombinesProductCustomField') {
            return CustomFieldManager.getProductCustomFields().length <= 0
        }
        else if (key === 'isIncludesAccountCustomField' || key === 'isCombinesAccountCustomField') {
            return CustomFieldManager.getAccountCustomFields().length <= 0
        }
        else if (key === 'isCombinesLineItemGlobalDiscountField') {
            return LineItemsGlobalDiscountParser.getIsContainsGlobalDiscount(this.props.documentData)
        }
        return false
    }

    getIsShowResetButton(key) {
        if (key === 'isCombinesLineItemGlobalDiscountField') {
            return !this.state.isCombinesLineItemGlobalDiscountField
        }
        return false
    }

    onResetClick(key) {
        if (key === 'isCombinesLineItemGlobalDiscountField') {
            this.resetLineItemsWithProductGlobalDiscounts()
        }
    }

    productCheckboxConfigurationOnClick(key) {
        var data = this.state.data
        if (key === 'isIncludesProductCustomField') {
            var isCombinesProductCustomField = this.state.isCombinesProductCustomField
            if (!this.state.isIncludesProductCustomField) {
                isCombinesProductCustomField = false
            }
            data = this.updateLineItemsWithProductCustomFields(!this.state.isIncludesProductCustomField, this.state.isIncludesPreferredVendorProductCustomField)
            this.setState({
                isIncludesProductCustomField: !this.state.isIncludesProductCustomField,
                isCombinesProductCustomField: isCombinesProductCustomField,
                data: data
            })
        }
        else if (key === 'isCombinesProductCustomField') {
            var isIncludesProductCustomField = this.state.isIncludesProductCustomField
            if (!this.state.isCombinesProductCustomField) {
                isIncludesProductCustomField = false
            }
            data = this.updateLineItemsWithProductCustomField(!this.state.isCombinesProductCustomField)
            this.setState({
                isCombinesProductCustomField: !this.state.isCombinesProductCustomField,
                isIncludesProductCustomField: isIncludesProductCustomField,
                data: data
            })
        }
        else if (key === 'isHSNSelected') {
            var isHSNSelected = this.state.isHSNSelected
            data.forEach(element => {
                if(element.type === TableColumnPopupType.productName || element.type === TableColumnPopupType.productNameWithDescription) {
                    element.isShowHsn = !isHSNSelected
                }
            });
            this.setState({
                isHSNSelected: !isHSNSelected,
                data: data
            })
        }
        else if (key === 'isAlterUOMSelected') {
            var isAlterUOMSelected = this.state.isAlterUOMSelected
            data.forEach(element => {
                if (element.type === TableColumnPopupType.uom || element.type === TableColumnPopupType.quantityWithUom) {
                    element.isShowAlterUOM = !isAlterUOMSelected
                }
            });
            this.setState({
                isAlterUOMSelected: !isAlterUOMSelected,
                data: data
            })
        }
        else if (key === 'isIncludesAccountCustomField') {
            var isCombinesAccountCustomField = this.state.isCombinesAccountCustomField
            if (!this.state.isIncludesAccountCustomField) {
                isCombinesAccountCustomField = false
            }
            data = this.updateLineItemsWithAccountCustomFields(!this.state.isIncludesAccountCustomField)
            this.setState({
                isIncludesAccountCustomField: !this.state.isIncludesAccountCustomField,
                isCombinesAccountCustomField: isCombinesAccountCustomField,
                data: data
            })
        }
        else if (key === 'isCombinesAccountCustomField') {
            var isIncludesAccountCustomField = this.state.isIncludesAccountCustomField
            if (!this.state.isCombinesAccountCustomField) {
                isIncludesAccountCustomField = false
            }
            data = this.updateLineItemsWithAccountCustomField(!this.state.isCombinesAccountCustomField)
            this.setState({
                isCombinesAccountCustomField: !this.state.isCombinesAccountCustomField,
                isIncludesAccountCustomField: isIncludesAccountCustomField,
                data: data
            })
        }
        else if (key === 'isGroupAdditionalChargesTax') {
            this.setState({
                isGroupAdditionalChargesTax: !this.state.isGroupAdditionalChargesTax,
            })
        }
        else if (key === 'isCombinesLineItemGlobalDiscountField') {
            data = this.updateLineItemsWithProductGlobalDiscounts(!this.state.isCombinesLineItemGlobalDiscountField)
            this.setState({
                isCombinesLineItemGlobalDiscountField: !this.state.isCombinesLineItemGlobalDiscountField,
                data: data
            })
        }
        else if (key === 'isIncludesPreferredVendorProductCustomField') {
            
            data = this.updateLineItemsWithProductCustomFields(this.state.isIncludesProductCustomField, !this.state.isIncludesPreferredVendorProductCustomField)
            this.setState({
                isIncludesPreferredVendorProductCustomField: !this.state.isIncludesPreferredVendorProductCustomField,
                data: data
            })
        }
    }

    getCombinesProductCustomField() {
        return <div>
            {this.getTableColumnCheckboxConfiguration('combines_product_custom_field', 'isCombinesProductCustomField')}
            <div className='ColumnDiv parent-width'>
                {this.state.isCombinesProductCustomField && this.getColumnList(this.state.combinedProductCustomFieldList, 2, TableColumnPopupType.productCustomField)}
            </div>
        </div>
    }

    getCombinesAccountCustomField() {
        return <div>
            {this.getTableColumnCheckboxConfiguration('combines_account_custom_field', 'isCombinesAccountCustomField')}
            <div className='ColumnDiv parent-width'>
                {this.state.isCombinesAccountCustomField && this.getColumnList(this.state.combinedAccountCustomFieldList, 2, TableColumnPopupType.accountCustomField)}
            </div>
        </div>
    }

    getCombinesLineItemGlobalDiscountField() {
        return <div>
            {this.getTableColumnCheckboxConfiguration('combines_line_item_global_discount', 'isCombinesLineItemGlobalDiscountField')}
            <div className='ColumnDiv parent-width'>
                {this.state.isCombinesAccountCustomField && this.getColumnList(this.state.combinedAccountCustomFieldList, 2, TableColumnPopupType.accountCustomField)}
            </div>
        </div>
    }

    getProductCustomFieldsConfiguration(key) {
        var productTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.productTableConfiguration
        if(productTableConfiguration === undefined || productTableConfiguration === null) {
            productTableConfiguration = TemplateSettingsManager.getDefaultProductTableConfiguration()
        }

        if (key === 'isIncludesProductCustomField') {
            return productTableConfiguration['isIncludesProductCustomField']
        }
        else if (key === 'isCombinesProductCustomField') {
            return productTableConfiguration['isCombinesProductCustomField']
        }
        else if (key === 'isCombinesLineItemGlobalDiscountField') {
            return productTableConfiguration['isCombinesLineItemGlobalDiscountField']
        }
        else if (key === 'isIncludesPreferredVendorProductCustomField') {
            return productTableConfiguration['isIncludesPreferredVendorProductCustomField']
        }
        // else if (key === 'isIncludesBomProduct') {
        //     return productTableConfiguration['isIncludesBomProduct'] ?? false
        // }
        return undefined
    }

    getAccountCustomFieldsConfiguration(key) {
        var accountTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.accountTableConfiguration
        if (accountTableConfiguration === undefined || accountTableConfiguration === null) {
            accountTableConfiguration = TemplateSettingsManager.getDefaultAccountTableConfiguration()
        }

        if (key === 'isIncludesAccountCustomField') {
            return accountTableConfiguration['isIncludesAccountCustomField']
        }
        else if (key === 'isCombinesAccountCustomField') {
            return accountTableConfiguration['isCombinesAccountCustomField']
        }
        return undefined
    }


    updateLineItemsWithProductCustomField(isCombinesProductCustomField) {
        var newData = []
        var existingCustomField = undefined
        if (isCombinesProductCustomField) {
            newData = []
            existingCustomField = this.state.data.find(x => x.type === TableColumnPopupType.productCustomField && x.code === undefined)
            this.state.data.forEach(element => {
                if (element.code === undefined) {
                    newData.push(element)
                }
            });
            if(existingCustomField === undefined) {
                var item = {}
                item.type = TableColumnPopupType.productCustomField
                item.name = "product_custom_field"
                item.isSelected = false
                newData.push(item)
            }
        }
        else {
            this.state.data.forEach(element => {
                if (element.type !== TableColumnPopupType.productCustomField) {
                    newData.push(element)
                }
            });
        }

        newData.forEach((element, index) => {
            element.index = index
        });

        return newData
    }

    updateLineItemsWithProductCustomFields(isIncludesProductCustomField, isIncludesPreferredVendorProductCustomField) {
        var productCustomField = CustomFieldManager.getProductWithPreferredVendorCustomFields(isIncludesPreferredVendorProductCustomField)
        var newData = []
        if (isIncludesProductCustomField) {
            if (productCustomField.length > 0) {
                this.state.data.forEach(element => {
                    if (element.type !== TableColumnPopupType.productCustomField) {
                        newData.push(element)
                    }
                });
                productCustomField.forEach(element => {
                    var item = {}
                    var type = element.type
                    if(type === undefined) {
                        type = element.fieldType
                    }
                    item.code = element.code
                    item.name = element.label
                    item.cfType = type
                    item.type = TableColumnPopupType.productCustomField
                    item.isSelected = false
                    newData.push(item)
                });
            }
        }
        else {
            if (productCustomField.length > 0) {
                //remove product custom field
                this.state.data.forEach(element => {
                    if (element.code === undefined) {
                        newData.push(element)
                    }
                });
            }
        }

        newData.forEach((element, index) => {
            element.index = index
        });

        return newData
    }

    updateLineItemsWithProductGlobalDiscounts(isCombinesLineItemGlobalDiscountField) {
        var productGlobalDiscount = LineItemsGlobalDiscountParser.getGlobalDiscountList(this.props.documentData)
        var newData = []
        if (!isCombinesLineItemGlobalDiscountField) {
            if (productGlobalDiscount.length > 0) {
                this.state.data.forEach(element => {
                    if (element.type !== TableColumnPopupType.productGlobalDiscount) {
                        newData.push(element)
                    }
                });
                productGlobalDiscount.forEach(element => {
                    var item = {}
                    item.accountCode = element.accountCode
                    item.globalDiscountAccountCode = element.globalDiscountAccountCode
                    item.name = element.label
                    item.type = TableColumnPopupType.productGlobalDiscount
                    item.isSelected = false
                    newData.push(item)
                });
            }
        }
        else {
            if (productGlobalDiscount.length > 0) {
                this.state.data.forEach(element => {
                    if (element.globalDiscountAccountCode === undefined) {
                        newData.push(element)
                    }
                });
            }
            var item = {}
            item.type = TableColumnPopupType.productGlobalDiscount
            item.name = "global_discount"
            item.isSelected = false
            newData.push(item)
        }

        newData.forEach((element, index) => {
            element.index = index
        });

        return newData
    }

    resetLineItemsWithProductGlobalDiscounts() {
        var productGlobalDiscount = LineItemsGlobalDiscountParser.getGlobalDiscountList(this.props.documentData)
        var newData = []
        let isCombinesLineItemGlobalDiscountField = this.state.isCombinesLineItemGlobalDiscountField

        if(!isCombinesLineItemGlobalDiscountField) {
            this.state.data.forEach(element => {
                if (element.type !== TableColumnPopupType.productGlobalDiscount) {
                    newData.push(element)
                }
            });

            productGlobalDiscount.forEach(element => {
                var item = {}
                item.accountCode = element.accountCode
                item.globalDiscountAccountCode = element.globalDiscountAccountCode
                item.name = element.label
                item.type = TableColumnPopupType.productGlobalDiscount
                item.isSelected = false
                newData.push(item)
            });
            newData.forEach((element, index) => {
                element.index = index
            });

            this.setState({ data: newData })
        }
    }

    updateLineItemsWithAccountCustomField(isCombinesAccountCustomField) {
        var newData = []
        var existingCustomField = undefined
        if (isCombinesAccountCustomField) {
            newData = []
            existingCustomField = this.state.data.find(x => x.type === TableColumnPopupType.accountCustomField && x.code === undefined)
            this.state.data.forEach(element => {
                if (element.code === undefined) {
                    newData.push(element)
                }
            });
            if (existingCustomField === undefined) {
                var item = {}
                item.type = TableColumnPopupType.accountCustomField
                item.name = "account_custom_field"
                item.isSelected = false
                newData.push(item)
            }
        }
        else {
            this.state.data.forEach(element => {
                if (element.type !== TableColumnPopupType.accountCustomField) {
                    newData.push(element)
                }
            });
        }

        newData.forEach((element, index) => {
            element.index = index
        });

        return newData
    }

    updateLineItemsWithAccountCustomFields(isIncludesAccountCustomField) {
        var accountCustomField = CustomFieldManager.getAccountCustomFields()
        var newData = []
        if (isIncludesAccountCustomField) {
            if (accountCustomField.length > 0) {
                this.state.data.forEach(element => {
                    if (element.type !== TableColumnPopupType.accountCustomField) {
                        newData.push(element)
                    }
                });
                accountCustomField.forEach(element => {
                    var item = {}
                    item.code = element.code
                    item.name = element.label
                    item.cfType = element.type
                    item.type = TableColumnPopupType.accountCustomField
                    item.isSelected = false
                    newData.push(item)
                });
            }
        }
        else {
            if (accountCustomField.length > 0) {
                //remove product custom field
                this.state.data.forEach(element => {
                    if (element.code === undefined) {
                        newData.push(element)
                    }
                });
            }
        }

        newData.forEach((element, index) => {
            element.index = index
        });

        return newData
    }

    getLineItemOptionSection() {
        return (
            <div style={{width: '100%', paddingBottom: 10, paddingTop: 5}}>
                <div className='fontStyleBold pl-r pr-r user-select-none' style={{
                    textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                    fontSize: 14,
                }}>
                    {getLocalisedText('line_items')}
                </div>
                {(this.getIsShowProductCustomFieldOption() || getIsSalesReturn(this.props.documentData.type)) && this.getTableColumnCheckboxConfiguration('includes_product_custom_field', 'isIncludesProductCustomField')}
                {(this.getIsShowProductCustomFieldOption() && (getIsOrder(this.props.documentData.type) || getIsBill(this.props.documentData.type))) && this.state.isIncludesProductCustomField && this.getTableColumnCheckboxConfiguration('Preferred Vendor Custom Field', 'isIncludesPreferredVendorProductCustomField')}
                {((this.getIsShowProductCustomFieldOption() || getIsSalesReturn(this.props.documentData.type)) && this.hideForDocument()) && this.getCombinesProductCustomField()}
                {this.getIsShowProductCustomFieldOption() && !getIsJobWorkOutOrder(this.props.documentData.type) && !getIsRequestForQuotation(this.props.documentData.type) && !getIsPurchaseInwardQuotation(this.props.documentData.type) && !getIsStockRequest(this.props.documentData.type) && !getIsStockIssue(this.props.documentData.type) && this.hideForDocument() && this.getCombinesLineItemGlobalDiscountField()}
                {this.getIsShowAccountCustomFieldOption() && this.getTableColumnCheckboxConfiguration('includes_account_custom_field', 'isIncludesAccountCustomField')}
                {this.getIsShowAccountCustomFieldOption() && this.getCombinesAccountCustomField()}
                {Utility.getIsIndiaCountry(this.props.documentData) && this.getIsProductModule() && this.getTableColumnCheckboxConfiguration('includes_hsn_or_sac_code', 'isHSNSelected')}
                {this.getIsProductModule() && !getIsJobWorkOutOrder(this.props.documentData.type) && this.getTableColumnCheckboxConfiguration('includes_uom_schema', 'isAlterUOMSelected')}
                {/* {Utility.getIsContainBomProduct(this.props.documentData.type) && this.getTableColumnCheckboxConfiguration('bom_product_table', 'isIncludesBomProduct')} */}
                {Utility.getIsIndiaCountry(this.props.documentData) && this.getIsProductModule() && this.hideForDocument() && this.getTableColumnCheckboxConfiguration('is_group_additional_charges_tax', 'isGroupAdditionalChargesTax')}
                {this.getOptions(this.getLineItemData(), true, false)}
            </div>
        )
    }

    getLineItemData() {
        var data = this.state.data.filter(x => !this.state.footerList.includes(x.type))
        //add sorting index if not exist
        if(data.length > 0) {
            var firstIndex = data[0].index
            if(firstIndex === undefined || firstIndex === null) {
                var newData = data
                newData.forEach((element, index) => {
                    element['index'] = index
                });
                data = newData
            }
        }
        return data
    }

    getFooterItemOptionSection() {
        return (
            <div style={{ width: '100%', paddingBottom: 10, paddingTop: 5 }}>
                <div className='fontStyleBold pl-r pr-r user-select-none' style={{
                    textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                    fontSize: 14,
                }}>
                    {getLocalisedText('footer')}
                </div>
                {this.getOptions(this.getFooterData(), false, false)}
            </div>
        )
    }

    getFooterData() {
        return this.state.data.filter(x => this.state.footerList.includes(x.type))
    }

    getCanEditTitle(item) {
        return item.type !== TableColumnPopupType.additionalCharge 
    }

    getOptions(list, isShowSortAndEditWidthOption, isSignatureList) {
        var newList = list
        if(newList.length > 0) {
            var index = newList[0].index
            if (index !== undefined && index !== null) {
                newList = list.sort((a, b) => a.index - b.index)
            }
        }
        return newList.map((item) => {
            return <TableColumnPopupRow
                        documentData={this.props.documentData}
                        onUpdateName={(name) => this.onUpdateName(item, name, isSignatureList)}
                        onUpdatedIsSelected={(isSelected) => {
                            this.onUpdatedIsSelected(item, isSelected, isSignatureList)
                        }}
                        item={item}
                        options={isSignatureList ? this.getSignatureDropDownOptions(item.type) : this.getDropDownOptions(item)}
                        isShowSortAndEditWidthOption={isShowSortAndEditWidthOption}
                        canEditTitle={this.getCanEditTitle(item)}
                        handleDrag={this.handleDrag}
                        handleDrop={(ev) => this.handleDrop(ev, isSignatureList)}
                        onDragOver={this.onDragOver}
                        onDragEnd={this.onDragEnd}
                        onCurrencySelect={(item, showAsCurrency) => this.onCurrencySelect(item, showAsCurrency)}
                    />
        })
    }

    getSignatureItemOptionSection() {
        return (
            <div style={{ width: '100%', paddingBottom: 10, paddingTop: 5 }}>
                <div className='fontStyleBold pl-r pr-r' style={{
                    textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                    fontSize: 14,
                }}>
                    {getLocalisedText('signature_section')}
                </div>
                {this.getOptions(this.state.signatureList, true, true)}
            </div>
        )
    }

    getSignatureDropDownOptions(type) {
        switch (type) {
            case SignatureColumnType.preparedBy:
                return ['prepared_by', 'other']
            case SignatureColumnType.approvedBy:
                return ['approved_by', 'other']
            case SignatureColumnType.name:
                return ['name', 'other']
            case SignatureColumnType.signature:
                return ['signature', 'other']
            default:
                return []
        }
    }

    getIsContainFooter(list) {
        if(this.getIsProductAccountTable()) {
            return false
        }
        else if(this.getFooterData(list).length > 0) {
            return true
        }
        return false
    }

    getIsContainsSignatureSection() {
        if (!getIsFulfillment(this.props.documentData.type) && !getIsGoodsReceipt(this.props.documentData.type)) {
            return false
        }

        if (getIsGoodsReceipt(this.props.documentData.type) && getIsGoodsReceiptNoteTemplate(this.props.documentData.templateId)) {
            return false
        }

        var signatureList = TemplateSettingsManager.defaultTemplateSettings.signatureInfo
        if (signatureList === undefined || signatureList === null) {
            return false
        }

        return signatureList.length > 0
    }

    getTotalSection() {
        return <div className='ColumnDiv pt-s pb-s parent-width'>
            <div className='RowDiv'
                style={{
                    paddingLeft: 13,
                }}>
                <input type="checkbox"
                    checked={this.state.isTotalSectionSelected}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        this.setState({
                            isTotalSectionSelected: !this.state.isTotalSectionSelected
                        })
                    }}
                />
                <div
                    className='fontStyleBold user-select-none'
                    style={{
                        fontSize: 14,
                        paddingLeft: 10,
                        textAlign: 'left',
                        alignItems: 'stretch',
                    }}>
                    {getLocalisedText('total_row')}
                </div>
            </div>
            <div className='ColumnDiv parent-width'>
                {this.getIsContainsTotalSection() && this.getTotalSectionColumnList()}
            </div>
        </div>
    }

    getTotalSectionColumnList() {
        if(this.props.data === undefined || this.props.data === null) {
            return
        }
        if(this.props.data.length === 0) {
            return
        }

        var selectedList = this.state.data.filter(x => x.isSelected)
        var newTotalColumnList = []
        if (selectedList !== undefined && selectedList !== null) {
            if(selectedList.length > 0) {
                var totalColumnList = TableTotalColumnList
                if (getIsJournalEntry(this.props.documentData.type)) {
                    totalColumnList = TableTotalColumnListForJournalEntry
                }
                if (getIsSOACustomer(this.props.documentData.type) || getIsSOAVendor(this.props.documentData.type)) {
                    totalColumnList = TableTotalColumnListForSOA
                }
                var numericProductCustomFields = CustomFieldManager.getNumericProductCustomField(selectedList)
                var numericProductCustomFieldCodes = numericProductCustomFields.map(x => x.code)
                selectedList.forEach(element => {
                    var totalColumn = {}
                    if (totalColumnList.includes(element.type)) {
                        totalColumn = {
                            type: element.type,
                            isVisible: this.getSelectedTotalColumn(this.state.totalColumnList, element)
                        }
                        newTotalColumnList.push(totalColumn)
                    }
                    else if (numericProductCustomFieldCodes.includes(element.code)) {
                        totalColumn = {
                            type: element.type,
                            code: element.code,
                            isVisible: this.getSelectedTotalColumn(this.state.totalColumnList, element)
                        }
                        newTotalColumnList.push(totalColumn)
                    }
                });
            }
        }
        return this.getColumnList(newTotalColumnList)
    }

    getSelectedTotalColumn(totalColumnList, element) {
        var isSelected = false
        var item = totalColumnList.find(x => x.type === element.type && x.code === element.code)
        if (item !== undefined) {
            isSelected = true
        }
        return isSelected
    }

    getColumnList(list, size, listType) {
        var newArraySize = 2
        if(size !== undefined) {
            newArraySize = size
        }
        var newList = []
        var rowCount = Math.ceil(list.length / newArraySize)

        for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
            var startIndex = rowIndex * newArraySize
            var tempList = []
            for (let index = startIndex; index < startIndex + newArraySize; index++) {
                var data = list[index]
                if (data !== undefined && data !== null) {
                    tempList.push(data)
                }
                else {
                    tempList.push(undefined)
                }
            }
            newList.push(tempList)
        }

        var columnList = newList.map((row) => {
            var columns = row.map((item) => {
                return this.getColumnItem(item, list, listType)
            });
            return <div className='RowDiv parent-width'
                style={{
                    paddingTop: getVW(4),
                    paddingBottom: getVW(4),
                }}>
                {columns}
            </div>
        });

        return columnList
    }

    getColumnItem(element, list, listType) {
        if(element === undefined) {
            return <></>
        }
        return <div className='RowDiv'
            style={{
                cursor: 'pointer',
                paddingLeft: 13,
            }}
            onClick={() => {
                this.onColumnItemOnClick(element, list, listType)
            }}
        >
            <input type="checkbox"
                style={{
                    cursor: 'pointer',
                }}
                checked={this.onColumnItemCheck(element, listType)}
            />
            <div className='RowDiv user-select-none'
                style={{
                    cursor: 'pointer',
                    fontSize: 14,
                    paddingLeft: 10,
                    textAlign: 'left',
                }}>
                {getLocalisedText(this.getColumnNameByType(element, listType))}
            </div>
        </div>
    }

    onColumnItemCheck(element, listType) {
        if (listType === TableColumnPopupType.productCustomField) {
            return element.isSelected
        }
        else if (listType === TableColumnPopupType.accountCustomField) {
            return element.isSelected
        }
        else {
            return element.isVisible
        }
    }

    onColumnItemOnClick(element, list, listType) {
        if(listType === TableColumnPopupType.productCustomField) {
            this.onUpdateProductCustomFieldList(element)
        }
        else if (listType === TableColumnPopupType.accountCustomField) {
            this.onUpdateAccountCustomFieldList(element)
        }
        else {
            this.onUpdateTotalColumnItem(element, list)
        }
    }

    onUpdateTotalColumnItem(element, list) {
        var item = this.state.totalColumnList.find(x => x.type === element.type)
        var newList = []
        if(item !== undefined) {
            //remove
            this.state.totalColumnList.forEach(columnItem => {
                if (columnItem.type !== item.type) {
                    newList.push(columnItem)
                }
            });
        }
        else {
            //insert
            newList = this.state.totalColumnList
            var newItem = {
                type: element.type,
                code: element.code
            }
            newList.push(newItem)
        }

        this.setState({
            totalColumnList: newList
        })
    }

    onUpdateProductCustomFieldList(item) {
        var newList = this.state.combinedProductCustomFieldList
        newList.forEach(element => {
            if(element.code === item.code) {
                element.isSelected = !item.isSelected
            }
        });
        this.setState({
            combinedProductCustomFieldList: newList
        })
    }

    onUpdateAccountCustomFieldList(item) {
        var newList = this.state.combinedAccountCustomFieldList
        newList.forEach(element => {
            if (element.code === item.code) {
                element.isSelected = !item.isSelected
            }
        });
        this.setState({
            combinedAccountCustomFieldList: newList
        })
    }

    getColumnNameByType(element, listType) {
        if(listType === TableColumnPopupType.productCustomField) {
            return element.label
        }
        else if (listType === TableColumnPopupType.accountCustomField) {
            return element.label
        }
        var items = this.state.data.filter(x => x.type === element.type && x.code === element.code)
        if(items.length > 0) {
            return items[0].name
        }
        return element.type
    }

    getIsProductModule() {
        if(this.props.data !== undefined && this.props.data !== null) {
            if(this.props.data.length > 0) {
                var productTypeList = this.props.data.map(x => x.type)
                if(productTypeList.includes(TableColumnPopupType.quantity) &&
                    productTypeList.includes(TableColumnPopupType.unitPrice)) {
                        return true
                }
            }
        }
        return false
    }

    getIsShowProductCustomFieldOption() {
        if (this.getIsProductModule() 
            || getIsRequestForQuotation(this.props.documentData.type)
            || getIsPurchaseInwardQuotation(this.props.documentData.type)
            || getIsFulfillment(this.props.documentData.type)
            || getIsGoodsReceipt(this.props.documentData.type)
            || getIsStockTransfer(this.props.documentData.type)
            || getIsStockAdjustment(this.props.documentData.type)
            || getIsExpenseOrDeposit(this.props.documentData.type)
            || getIsJobWorkOutOrder(this.props.documentData.type)
            || getIsStockRequest(this.props.documentData.type)
            || getIsStockIssue(this.props.documentData.type)
            ) {
            return true
        }
        return false
    }
    hideForDocument() {
        if(getIsServiceRequisition(this.props.documentData.type) ||
        getIsPurchaseRequisition(this.props.documentData.type) ) {
            return false
        }
        return true
    }

    getIsContainsTotalSection() {
        return this.state.isTotalSectionSelected
    }

    getIsShowAccountCustomFieldOption() {
        if (getIsJournalEntry(this.props.documentData.type)) {
            return true
        }
        if (getIsExpenseBill(this.props.documentData.type, this.props.documentData.lineItems)) {
            return true
        }
        return false
    }

    onUpdateName(item, value, isSignatureList) {
        var newTableInfo = isSignatureList ? this.state.signatureList : this.state.data
        newTableInfo.forEach(element => {
            if (element.type === item.type && element.index === item.index) {
                element.name = value
            }
        });
        this.onUpdateState(newTableInfo, isSignatureList)
    }
    
    onCurrencySelect(item, showAsCurrency) {
        var newTableInfo = this.state.data
        newTableInfo.forEach(element => {
            if (element.type === item.type && element.index === item.index) {
                element.showAsCurrency = showAsCurrency
            }
        });
        this.onUpdateState(newTableInfo, undefined)
    }

    onUpdatedIsSelected(item, value, isSignatureList) {
        var newTableInfo = isSignatureList ? this.state.signatureList : this.state.data
        var types = newTableInfo.map(x => x.type)
        if (types.includes(TableColumnPopupType.productNameWithDescription) || getIsFulfillment(this.props.documentData.type)) {
            if (item.type === TableColumnPopupType.productNameWithDescription) {
                newTableInfo.forEach(element => {
                    if (element.type === item.type) {
                        element.isSelected = value
                        element.width = undefined
                    }
                    else if (element.type === TableColumnPopupType.productName || element.type === TableColumnPopupType.description ) {
                        element.isSelected = false
                        element.width = undefined
                    }
                });
            }
            else if (item.type === TableColumnPopupType.productName || item.type === TableColumnPopupType.description) {
                newTableInfo.forEach(element => {
                    if (element.type === item.type) {
                        element.isSelected = value
                        element.width = undefined
                    }
                    else if (element.type === TableColumnPopupType.productNameWithDescription) {
                        element.isSelected = false
                        element.width = undefined
                    }
                });
            }
            else if (item.type === TableColumnPopupType.quantityWithUom) {
                newTableInfo.forEach(element => {
                    if (element.type === item.type) {
                        element.isSelected = value
                        element.width = undefined
                    }
                    else if (element.type === TableColumnPopupType.quantity || element.type === TableColumnPopupType.uom) {
                        element.isSelected = false
                        element.width = undefined
                    }
                });
            }
            else if (item.type === TableColumnPopupType.uom || item.type === TableColumnPopupType.quantity) {
                newTableInfo.forEach(element => {
                    if (element.type === item.type) {
                        element.isSelected = value
                        element.width = undefined
                    }
                    else if (element.type === TableColumnPopupType.quantityWithUom) {
                        element.isSelected = false
                        element.width = undefined
                    }
                });
            }
            else if (item.type === TableColumnPopupType.serialBatch) {
                newTableInfo.forEach(element => {
                    if (element.type === item.type) {
                        element.isSelected = value
                        element.width = undefined
                    }
                    else if (element.type === TableColumnPopupType.serialNumber || element.type === TableColumnPopupType.batchNumber ||
                        element.type === TableColumnPopupType.manufacturingDate || element.type === TableColumnPopupType.expiryDate) {
                        element.isSelected = false
                        element.width = undefined
                    }
                });
            }
            else if (item.type === TableColumnPopupType.serialNumber || item.type === TableColumnPopupType.batchNumber ||
                item.type === TableColumnPopupType.manufacturingDate || item.type === TableColumnPopupType.expiryDate) {
                newTableInfo.forEach(element => {
                    if (element.type === item.type) {
                        element.isSelected = value
                        element.width = undefined
                    }
                    else if (element.type === TableColumnPopupType.serialBatch) {
                        element.isSelected = false
                        element.width = undefined
                    }
                });
            }
            else {
                newTableInfo.forEach(element => {
                    if (element.type === item.type && item.index === element.index) {
                        element.isSelected = value
                        element.width = undefined
                    }
                });
            }
        }
        else {
            newTableInfo.forEach(element => {
                if (element.type === item.type && item.index === element.index) {
                    element.isSelected = value
                    element.width = undefined
                }
            });
        }
        this.onUpdateState(newTableInfo, isSignatureList)
    }

    onUpdateState(newTableInfo, isSignatureList) {
        if (isSignatureList) {
            this.setState({
                signatureList: newTableInfo
            })
        }
        else {
            this.setState({
                data: newTableInfo
            })
        }
    }

    handleDrag = (ev) => {
        var startIndex = ev.currentTarget.id
        if (startIndex !== undefined && startIndex !== this.startIndex) {
            ev.dataTransfer.dropEffect = "move";
            this.startIndex = ev.currentTarget.id
        }
    };

    handleDrop = (ev, isSignatureList) => {
        var endIndex = ev.currentTarget.id
        if (endIndex !== undefined) {
            this.onRowIndexChange(endIndex, isSignatureList)
        }
    };

    onDragOver = (ev) => {
        var dragOverIndex = ev.currentTarget.id
        ev.preventDefault()
        if (dragOverIndex !== undefined) {
            if (dragOverIndex !== this.dragOverIndex && this.startIndex !== undefined) {
                this.removeTransition()
                this.addAnimation(ev.currentTarget.id, ev)
                this.dragOverIndex = dragOverIndex
            }
        }
    }

    onDragEnd = (ev) => {
        ev.preventDefault()
        this.removeTransition()
        if (this.startIndex !== undefined) {
            var startIndex = Number(this.startIndex.replace('row_id_', ''))
            this.showSelectedRow(startIndex)
            this.removeTransition()
        }
    }

    addAnimation = (targetIndex) => {
        var startIndex = undefined
        var endIndex = Number(targetIndex.replace('row_id_', ''))

        if (this.startIndex !== undefined) {
            startIndex = Number(this.startIndex.replace('row_id_', ''))
        }

        if (startIndex !== undefined && endIndex !== undefined) {
            if (startIndex !== endIndex) {
                let columnEle = document.getElementById('custom_field_table_popup');
                if (columnEle && columnEle.children) {
                    Array.from(columnEle.children).forEach((element, index) => {
                        if (endIndex > startIndex && endIndex > 0) {
                            if (index >= endIndex) {
                                this.hideSelectedRow(startIndex)
                                if (startIndex === endIndex) return;
                                element.style.transform = `translate(0,${element.clientHeight}px)`;
                            }
                        }
                        else if (endIndex !== 0) {
                            if (index > endIndex) {
                                this.hideSelectedRow(startIndex)
                                if (startIndex === endIndex) return;
                                element.style.transform = `translate(0,${element.clientHeight}px)`;
                            }
                        }
                    })
                }
            }
        }
    }

    removeTransition = () => {
        let columnEle = document.getElementById('custom_field_table_popup');
        if (columnEle && columnEle.children) {
            Array.from(columnEle.children).forEach((element, index) => {
                element.style.transform = null
                element.style.display = 'flex'
            })
        }
    }

    hideSelectedRow = (startIndex) => {
        let textCol = document.getElementById('row_text_id_' + startIndex);
        if (textCol) {
            if (textCol.innerHTML !== '&nbsp;') {
                this.startIndexText = textCol.innerHTML
            }
            textCol.innerHTML = '&nbsp;'
        }

        // let editCol = document.getElementById('row_edit_id_' + startIndex);
        // if (editCol) {
        //     editCol.style.display = 'none'
        // }

        // let dragCol = document.getElementById('row_drag_id_' + startIndex);
        // if (dragCol) {
        //     dragCol.style.display = 'none'
        // }

        // let checkboxCol = document.getElementById('row_checkbox_id_' + startIndex);
        // if (checkboxCol) {
        //     checkboxCol.style.display = 'none'
        // }
    }

    showSelectedRow = (startIndex) => {
        let textCol = document.getElementById('row_text_id_' + startIndex);
        if (textCol && this.startIndexText) {
            textCol.innerHTML = this.startIndexText
        }

        // let editCol = document.getElementById('row_edit_id_' + startIndex);
        // if (editCol) {
        //     editCol.style.display = 'flex'
        // }

        // let dragCol = document.getElementById('row_drag_id_' + startIndex);
        // if (dragCol) {
        //     dragCol.style.display = 'flex'
        // }

        // let checkboxCol = document.getElementById('row_checkbox_id_' + startIndex);
        // if (checkboxCol) {
        //     checkboxCol.style.display = 'flex'
        // }
    }

    onRowIndexChange(endIndex, isSignatureList) {
        if (this.startIndex === undefined) {
            return
        }

        var startIndex = Number(this.startIndex.replace('row_id_', ''))
        if (this.startIndex === endIndex) {
            this.startIndex = undefined
            return
        }

        this.showSelectedRow(startIndex)
        var newArray = []
        var newData = isSignatureList ? this.state.signatureList : this.state.data
        newData.forEach((element) => {
            if (Number(element.index) !== startIndex) {
                newArray.push(element)
            }
        });
        var newIndex = Number(endIndex.replace('row_id_', ''))

        var sortedList = []
        var selectedItem = newData.filter(x => x.index === startIndex)
        if (selectedItem.length > 0) {
            newArray.splice(newIndex, 0, selectedItem[0])
        }
        newArray.forEach((element, index) => {
            var newRowItem = element
            newRowItem.index = index
            sortedList.push(newRowItem)
        });
        this.setState({
            data: isSignatureList ? this.state.data : sortedList,
            signatureList: isSignatureList ? sortedList : this.state.signatureList,
            selectedIndex: undefined
        }, () => {
            this.startIndex = undefined
            this.dragIndex = undefined
            this.removeTransition()
        })
    }

    getDropDownOptions(item) {
        var isExpenseOrCreditNote = (getIsAccountModule(undefined, this.props.documentData.type) && !this.getIsProductAccountTable()) || getIsExpenseBill(this.props.documentData.type, this.props.documentData.lineItems)
        if (isExpenseOrCreditNote) {
            switch (item.type) {
                case TableColumnPopupType.accountName:
                    return [ExpenseMapper.getLabel().accounts, 'services', 'other']
                case TableColumnPopupType.description:
                    return ['description', ExpenseMapper.getLabel().accountDescription, 'notes', 'other']
                case TableColumnPopupType.amount:
                    if (getIsExpenseOrDeposit(item.type)) {
                        return [ExpenseMapper.getLabel().paymentAmount, getLocalisedText('other')]
                    }
                    else {
                        return [CreditNoteMapper.getLabel().lineAmount, getLocalisedText('other')]
                    }
                case TableColumnPopupType.tax:
                    return ['tax', 'tax_rate', ExpenseMapper.getLabel().taxAmount, 'other']
                case TableColumnPopupType.taxAmount:
                    return [ExpenseMapper.getLabel().taxAmount, 'other']
                case TableColumnPopupType.paymentAmount:
                    return [ExpenseMapper.getLabel().paymentAmount, 'other']
                case TableColumnPopupType.totalTaxAmount:
                    return ['total_tax_amount', 'other']
                case TableColumnPopupType.beforeTax:
                    return ['before_tax','other']
                case TableColumnPopupType.amountInWords:
                    return ['amount_in_words', 'other']
                case TableColumnPopupType.subTotalAmount:
                    return ['subtotal', 'other']
                case TableColumnPopupType.totalAmount:
                    return ['total', 'other']
                case TableColumnPopupType.outstandingBalance:
                    return ['outstanding_balance', 'other']
                case TableColumnPopupType.outstandingBalanceIncludesCurrent:
                    return ['outstanding_balance_includes_current', 'other']
                case TableColumnPopupType.productCustomField:
                    if (item.code !== undefined) {
                        return [item.name, 'other']
                    }
                    return ['product_custom_field', 'other']
                case TableColumnPopupType.accountCustomField:
                    if (item.code !== undefined) {
                        return [item.name, 'other']
                    }
                    return ['account_custom_field', 'other']
                default:
                    return []
            }
        }
        else {
            switch (item.type) {
                case TableColumnPopupType.lineNumber:
                    return ['#', 'no.', 'number', 'other']
                case TableColumnPopupType.code:
                    return ['sn_number', 'item_number', 'item_code', 'other']
                case TableColumnPopupType.image:
                    return ['image', 'other']
                case TableColumnPopupType.productName:
                    return ['products', 'services', 'items', 'other']
                case TableColumnPopupType.description:
                    return ['description', 'notes', 'other']
                case TableColumnPopupType.hsn:
                    return ['hsn_or_sac', 'other']
                case TableColumnPopupType.productCustomField:
                    if (item.code !== undefined) {
                        return [item.name, 'other']
                    }
                    return ['product_custom_field', 'other']
                case TableColumnPopupType.quantity:
                    return ['qty', 'hours', 'other']
                case TableColumnPopupType.unitPrice:
                    return ['unit price', 'unit_price_before_discount', 'price', 'rate', 'other']
                case TableColumnPopupType.amount:
                    return ['amount', 'other']
                case TableColumnPopupType.tax:
                    return ['tax', 'sales_tax', 'tax_rate', 'other']
                case TableColumnPopupType.discount:
                    return ['discount', 'other']
                case TableColumnPopupType.globalDiscount:
                    return ['global_discount', 'other']
                case TableColumnPopupType.additionalCharge:
                    return ['additional_charges', 'other']
                case TableColumnPopupType.productTax:
                    return ['item_tax', 'tax', 'other']
                case TableColumnPopupType.productTaxAmount:
                    return ['tax_amount', 'tax', 'other']
                case TableColumnPopupType.productDiscount:
                    return ['item_discount', 'discount', 'other']
                case TableColumnPopupType.productGlobalDiscount:
                    return ['global_discount', 'discount', 'other']
                case TableColumnPopupType.productUnitPriceAfterDiscount:
                    return ['unit_price_after_discount', 'other']
                case TableColumnPopupType.productDiscountPerUnit:
                    return ['discount_per_unit', 'other']
                case TableColumnPopupType.uom:
                    return ['uom', 'other']
                case TableColumnPopupType.quantityWithUom:
                    return ['quantity_and_uom', 'other']
                case TableColumnPopupType.amountBeforeTax:
                    return ['amount_before_tax', 'other']
                case TableColumnPopupType.orderNo:
                    return ['order_no', 'bill_no', 'other']
                case TableColumnPopupType.requiredQuantity:
                    return ['required_qty', 'other']
                case TableColumnPopupType.receivedQuantity:
                    return ['received_qty', 'other']
                case TableColumnPopupType.committedQuantity:
                    return ['committed_qty', 'other']
                case TableColumnPopupType.warehouseCode:
                    return ['warehouse_code', 'other']
                case TableColumnPopupType.warehouseName:
                    return ['warehouse_name', 'other']
                case TableColumnPopupType.beforeTax:
                    return ['before_tax', 'other']
                case TableColumnPopupType.snNumber:
                    return ['sn_number', 'other']
                case TableColumnPopupType.serialBatch:
                    return ['serial_batch', 'other']
                case TableColumnPopupType.serialNumber:
                    return ['serial_number', 'other']
                case TableColumnPopupType.batchNumber:
                    return ['batch_number', 'other']
                case TableColumnPopupType.manufacturingDate:
                    return ['manufacturing_date', 'other']
                case TableColumnPopupType.expiryDate:
                    return ['expiry_date', 'other']
                case TableColumnPopupType.accountName:
                    return [ExpenseMapper.getLabel().accounts, 'other']
                case TableColumnPopupType.amountInWords:
                    return ['amount_in_words', 'other']
                case TableColumnPopupType.roundingOff:
                    return ['rounding_off', 'other']
                case TableColumnPopupType.productNameWithDescription:
                    return ['name_with_description', 'other']
                case TableColumnPopupType.invoiceNumber:
                    return ['invoice_number', 'other']
                case TableColumnPopupType.billNumber:
                    return ['bill_number', 'other']
                case TableColumnPopupType.totalInvoiceAmount:
                    return ['total_invoice_amount', 'other']
                case TableColumnPopupType.totalBillAmount:
                    return ['total_bill_amount', 'other']
                case TableColumnPopupType.paymentMade:
                    return ['payment_made', 'other']
                case TableColumnPopupType.currentDueAmount:
                    return ['current_due_amount', 'other']
                case TableColumnPopupType.currentBalance:
                    return ['current_balance', 'other']
                case TableColumnPopupType.paymentMedium:
                    return ['payment_medium', 'other']
                case TableColumnPopupType.date:
                    return ['date', 'other']
                case TableColumnPopupType.journalEntryNumber:
                    return ['journal_entry_number', 'other']
                case TableColumnPopupType.accountCode:
                    return ['account_code', 'other']
                case TableColumnPopupType.credit:
                    return ['credit', 'other']
                case TableColumnPopupType.debit:
                    return ['debit', 'other']
                case TableColumnPopupType.invoiceOrQuotation:
                    return ['invoice_or_quotation', 'invoice', 'quotation', 'other']
                case TableColumnPopupType.barcode:
                    return ['barcode', 'other']
                case TableColumnPopupType.quantityToPick:
                    return ['quantity_to_pick', 'other']
                case TableColumnPopupType.quantityRequiredForOrder:
                    return ['quantity_required_for_order', 'other']
                case TableColumnPopupType.picked:
                    return ['pick', 'other']
                case TableColumnPopupType.id:
                    return ['item_no', 'other']
                case TableColumnPopupType.unit:
                    return ['unit', 'other']
                case TableColumnPopupType.packedQuantity:
                    return ['packed_quantity', 'other']
                case TableColumnPopupType.delivery:
                    return ['delivery', 'other']
                case TableColumnPopupType.carrier:
                    return ['carrier', 'other']
                case TableColumnPopupType.numberOfCarton:
                    return ['number_of_carton', 'other']
                case TableColumnPopupType.weight:
                    return ['weight', 'other']
                case TableColumnPopupType.trackingNumber:
                    return ['tracking_number', 'other']
                case TableColumnPopupType.accountCustomField:
                    if (item.code !== undefined) {
                        return [item.name, 'other']
                    }
                    return ['account_custom_field', 'other']
                case TableColumnPopupType.subTotalAmount:
                    return ['subtotal', 'other']
                case TableColumnPopupType.totalAmount:
                    return ['total', 'other']
                case TableColumnPopupType.cogs:
                    return ['cogs', 'other']
                case TableColumnPopupType.outstandingBalance:
                    return ['outstanding_balance', 'other']
                case TableColumnPopupType.outstandingBalanceIncludesCurrent:
                    return ['outstanding_balance_includes_current', 'other']
                case TableColumnPopupType.orderPrepCompleted:
                    return ['order_prep_completed', 'other']
                case TableColumnPopupType.sourceWarehouseName:
                    return ['source', 'source_warehouse_name', 'other']
                case TableColumnPopupType.sourceWarehouseCode:
                    return ['source_warehouse_code', 'other']
                case TableColumnPopupType.destinationWarehouseName:
                    return ['destination', 'destination_warehouse_name', 'other']
                case TableColumnPopupType.destinationWarehouseCode:
                    return ['destination_warehouse_code', 'other']
                case TableColumnPopupType.expectedDeliveryDate:
                    return ['expected_delivery_date', 'other']
                case TableColumnPopupType.contactCode:
                    return ['contact_code', 'other']
                case TableColumnPopupType.contactName:
                    return ['contact_name', 'other']
                case TableColumnPopupType.parameterName:
                    return ['name', 'other']
                case TableColumnPopupType.parameterValue:
                    return ['value', 'other']
                case TableColumnPopupType.qcIncoming:
                    return ['incoming', 'other']
                case TableColumnPopupType.qcFinal:
                    return ['final', 'other']
                case TableColumnPopupType.fulfilledQty:
                    return ['fulfilled_quantity', 'other']
                case TableColumnPopupType.returnedQty:
                    return ['returned_quantity', 'other']
                case TableColumnPopupType.kindOfPacking:
                    return ['kind_of_packing', 'other']
                case TableColumnPopupType.dueOn:
                    return ['due_on', 'other']
                case TableColumnPopupType.rate:
                    return ['rate', 'other']
                case TableColumnPopupType.per:
                    return ['per', 'other']
                case TableColumnPopupType.cost:
                    return ['cost', 'other']
                case TableColumnPopupType.targetWarehouseName:
                    return ['target_warehouse_name', 'other']
                case TableColumnPopupType.targetWarehouseCode:
                    return ['target_warehouse_code', 'other']
                case TableColumnPopupType.selectedRow:
                    return ['selected_row', 'other']
                case TableColumnPopupType.selectedRack:
                    return ['selected_rack', 'other']
                case TableColumnPopupType.selectedBin:
                    return ['selected_bin', 'other']
                case TableColumnPopupType.requestedQty:
                    return ['requested_qty', 'other']
                case TableColumnPopupType.requiredQty:
                    return ['required_qty', 'other']
                case TableColumnPopupType.issuedQty:
                    return ['issued_qty', 'other']
                case TableColumnPopupType.totalTaxAmount:
                    return ['total_tax_amount', 'other']
                case TableColumnPopupType.qtyConvertedToWo:
                    return ['wo_qty', 'other']
                case TableColumnPopupType.availableQuantity:
                    return ['available_qty', 'other']
                case TableColumnPopupType.refDocument:
                case TableColumnPopupType.refDocumentDate:
                    return ['other']
                case TableColumnPopupType.POBillNoDate:
                    return ['po_bill_no_date', 'other']
                case TableColumnPopupType.shortExcessRejectedQty:
                    return ['short_excess_rejected_qty', 'other']
                case TableColumnPopupType.acceptedQty:
                    return ['accepted_qty', 'other']
                case TableColumnPopupType.basicAmt:
                    return ['basic_amt', 'other']
                case TableColumnPopupType.charges:
                    return ['charges', 'other']
                case TableColumnPopupType.documentDate:
                    return ['document_date', 'other']
                case TableColumnPopupType.documentNumber:
                    return ['document_number', 'other']
                case TableColumnPopupType.particular:
                    return ['particular', 'other']
                case TableColumnPopupType.documentType:
                    return ['document_type', 'other']
                case TableColumnPopupType.amountInBase:
                    return ['amount_in_base', 'other']
                case TableColumnPopupType.balanceInBase:
                    return ['balance_in_base', 'other']
                case TableColumnPopupType.currency:
                    return ['currency', 'other']
                case TableColumnPopupType.memo:
                    return ['memo', 'other']
                case TableColumnPopupType.serialBatchCustomFields:
                    return ['serial_batch_custom_fields', 'other']
                default:
                    return []
            }
        }
    }

    cancelButtonTapped = () => {
        this.setState( {data: this.props.data}, () => {
            this.props.onTableColumnPopupClose()
        })
    }

    saveButtonTapped = () => {
        if(this.getIsContainsSignatureSection()) {
            TemplateSettingsManager.updateSignatureInfo(this.state.signatureList)
        }
        if (this.getIsShowProductCustomFieldOption() 
            || getIsJournalEntry(this.props.documentData.type) 
            || getIsSOACustomer(this.props.documentData.type) 
            || getIsSOAVendor(this.props.documentData.type)) {
            var totalColumnList = this.state.totalColumnList
            if (this.state.isCombinesProductCustomField) {
                totalColumnList = this.state.totalColumnList.filter(x => x.type !== TableColumnPopupType.productCustomField)
            }
            var totalColumnSection = {
                items: totalColumnList,
                isVisible: this.state.isTotalSectionSelected
            }
            TemplateSettingsManager.updateTotalColumnSection(totalColumnSection)
        }
        if (this.getIsShowProductCustomFieldOption() || getIsSalesReturn(this.props.documentData.type)) {
            var productTableConfiguration = {
                isIncludesProductCustomField: this.state.isIncludesProductCustomField,
                isCombinesProductCustomField: this.state.isCombinesProductCustomField,
                combinedProductCustomField: this.state.combinedProductCustomFieldList,
                isCombinesLineItemGlobalDiscountField: this.state.isCombinesLineItemGlobalDiscountField,
                isIncludesPreferredVendorProductCustomField: this.state.isIncludesPreferredVendorProductCustomField,
            }
            TemplateSettingsManager.updateProductTableConfiguration(productTableConfiguration)
        }

        
        if (this.getIsShowAccountCustomFieldOption()) {
            var accountTableConfiguration = {
                isIncludesAccountCustomField: this.state.isIncludesAccountCustomField,
                isCombinesAccountCustomField: this.state.isCombinesAccountCustomField,
                combinedAccountCustomField: this.state.combinedAccountCustomFieldList,
            }
            TemplateSettingsManager.updateAccountTableConfiguration(accountTableConfiguration)
        }

        if (Utility.getIsIndiaCountry(this.props.documentData) && this.getIsProductModule()) {
            TemplateSettingsManager.updateIsGroupAdditionalTax(this.state.isGroupAdditionalChargesTax)
        }

        this.props.onTableColumnPopupSave(this.state.data, this.props.tableType)
    }
}
